import React, { useState } from 'react';
//import MaterialTable from './components/MaterialTable'; // Adjust the import path as necessary
import Table from './components/Table'; // Adjust the import path as necessary
import PrivacyPolicyModal from './components/PrivacyPolicyModal'; 
import { DataProvider } from './data/DataContext';
const App = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <DataProvider>
            <div style={{ textAlign: 'center' }}>
                <h1>Disk Price List</h1>
                <button onClick={openModal} style={{ marginTop: '20px', marginBottom: '20px' }}>View Privacy Policy</button>
                <PrivacyPolicyModal isOpen={isModalOpen} onClose={closeModal} />
                <Table />
            </div>
        </DataProvider>
    );
};

export default App;