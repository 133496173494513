import React, { createContext, useContext, useState } from 'react';

// Create a Context
const DataContext = createContext();

// Provider component
export const DataProvider = ({ children }) => {
    const [data, setData] = useState(
      
      [
        {
            "pricePerTB": "$2.916",
            "price": "$35",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B078J22TMZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3.840",
            "price": "$922",
            "capacity": "12 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07C7CKR23?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3.920",
            "price": "$941",
            "capacity": "12 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077LQSKT2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3.948",
            "price": "$947",
            "capacity": "12 TB x20",
            "warranty": "5 years",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079YPMMX5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.182",
            "price": "$1,004",
            "capacity": "12 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079KDJNWY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.333",
            "price": "$52",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079YLWNZ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.333",
            "price": "$52",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078J22TMZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.375",
            "price": "$1,050",
            "capacity": "12 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D4P99YC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.416",
            "price": "$53",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B9Q4VT5H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.465",
            "price": "$54",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077LK25K9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.467",
            "price": "$536",
            "capacity": "12 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFJXH2H5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.540",
            "price": "$545",
            "capacity": "12 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CGVZFNR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.582",
            "price": "$55",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D96RQLF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.583",
            "price": "$55",
            "capacity": "300 GB x40",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01KJTBV0Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4.861",
            "price": "$1,750",
            "capacity": "18 TB x20",
            "warranty": "2 years",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CYQHSDQN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.055",
            "price": "$91",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09GBGDMJN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.381",
            "price": "$97",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09HTNSCVK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.556",
            "price": "$5",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B007IPDTE4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.667",
            "price": "$1,360",
            "capacity": "12 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07LH77P56?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.673",
            "price": "$284",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C41R3FP8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.688",
            "price": "$68",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078KGVM84?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.696",
            "price": "$1,367",
            "capacity": "12 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07LH97JZ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.833",
            "price": "$350",
            "capacity": "6 TB x10",
            "warranty": "30 years",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN4QX8SH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5.899",
            "price": "$106",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09J92XYDJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.095",
            "price": "$73",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077LS2Z2C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.139",
            "price": "$111",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C6BQ1CPP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.167",
            "price": "$18",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00D7IMI4I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.362",
            "price": "$382",
            "capacity": "6 TB x10",
            "warranty": "1 month",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071GS58M4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.456",
            "price": "$775",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B017OUMWX6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.500",
            "price": "$39",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01KYKY5PQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.528",
            "price": "$39",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B018WJQKRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.616",
            "price": "$397",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0743K3W7Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.660",
            "price": "$10",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003H0O6MS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.665",
            "price": "$40",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01DP1ZOMO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.667",
            "price": "$600",
            "capacity": "18 TB x5",
            "warranty": "1 month",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPKFXYCK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.667",
            "price": "$200",
            "capacity": "6 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CDY4SXCG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.714",
            "price": "$604",
            "capacity": "18 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BLDXYW1T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.725",
            "price": "$807",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01E1XNESQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6.998",
            "price": "$42",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B018J4H8EO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.015",
            "price": "$421",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0743JPB97?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.083",
            "price": "$425",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071GPS5G7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.133",
            "price": "$428",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07PK4GP8M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.158",
            "price": "$43",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B019DGCRAW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.167",
            "price": "$430",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07J6T6DMF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.167",
            "price": "$215",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TGP3KES?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.180",
            "price": "$18",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BPDKVIW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.180",
            "price": "$18",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008C4FYCK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.196",
            "price": "$18",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HXGRWQY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.250",
            "price": "$870",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BVMJJR8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.332",
            "price": "$44",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01NCB4ZZP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.413",
            "price": "$44",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N4RDLZ5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.424",
            "price": "$74",
            "capacity": "10 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RNFN6S9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.475",
            "price": "$3",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00084O3SS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.497",
            "price": "$30",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H8NYV6T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.656",
            "price": "$383",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BK9TM6K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.667",
            "price": "$23",
            "capacity": "3 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B004TLVKLO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.750",
            "price": "$78",
            "capacity": "10 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DL3HHXD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.752",
            "price": "$194",
            "capacity": "2.5 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089D63JVY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.800",
            "price": "$390",
            "capacity": "2.5 TB x20",
            "warranty": "2 years",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CYDWXQ3N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.800",
            "price": "$975",
            "capacity": "2.5 TB x50",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPFT3TQT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.800",
            "price": "$20",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N5CRBNA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.823",
            "price": "$94",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07Y8GF5M6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.867",
            "price": "$12",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003F8MT5I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.880",
            "price": "$394",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00OGPHGK2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.899",
            "price": "$79",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BWKNZGL5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.917",
            "price": "$95",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6HCQRR1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.988",
            "price": "$80",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BSB7J2Z8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.990",
            "price": "$96",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CSMB8TYR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.995",
            "price": "$80",
            "capacity": "10 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09VY75NLH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.996",
            "price": "$20",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AAHS9R4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.999",
            "price": "$80",
            "capacity": "10 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DPKWLJR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7.999",
            "price": "$96",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H4YV359?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.000",
            "price": "$20",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BRF330Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.000",
            "price": "$48",
            "capacity": "6 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00JJIE8E8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.100",
            "price": "$405",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AAHS9J2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.116",
            "price": "$20",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00CNN2BV8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.125",
            "price": "$65",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00XS423SC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.152",
            "price": "$978",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07FB4WD3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.240",
            "price": "$21",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00E7MHG3G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.368",
            "price": "$21",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CJZNRAY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.374",
            "price": "$134",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C6BN3W5J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.553",
            "price": "$128",
            "capacity": "1.5 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008BNUAXK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.559",
            "price": "$1,027",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C45PHRMX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.562",
            "price": "$137",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09GT8RK6K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.568",
            "price": "$120",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09GT473KC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.568",
            "price": "$257",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005YNURV0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.571",
            "price": "$120",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C6CDSR9S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.571",
            "price": "$120",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BQSWTT55?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.631",
            "price": "$1,036",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B017OUMVVE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.680",
            "price": "$139",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BWL59XLF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.687",
            "price": "$139",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C6C4HFBB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.700",
            "price": "$22",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00ICHHWKO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.742",
            "price": "$140",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BYV2559Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.749",
            "price": "$70",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MK3T56C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.749",
            "price": "$140",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BWMLCWZ7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.749",
            "price": "$140",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09GRP2KXF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.749",
            "price": "$140",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BYTZWX7C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.749",
            "price": "$140",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C6BYNMNH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.750",
            "price": "$700",
            "capacity": "10 TB x8",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01I0FCV8U?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.750",
            "price": "$35",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07F7QM7WX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.750",
            "price": "$70",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00VO841AC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.750",
            "price": "$105",
            "capacity": "300 GB x40",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01KJTBV0Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.820",
            "price": "$13",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003KR4CXQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.833",
            "price": "$265",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003CNXO8W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.849",
            "price": "$124",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BYTWVS8K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.888",
            "price": "$89",
            "capacity": "10 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084292QX3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.918",
            "price": "$107",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07X93Z23X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.954",
            "price": "$107",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07JJK7S37?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.995",
            "price": "$90",
            "capacity": "10 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H4ZRG94?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$8.999",
            "price": "$126",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BYTVCWM9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.000",
            "price": "$22",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AHQUV3S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.000",
            "price": "$22",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AR6HJOM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.000",
            "price": "$90",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073BLV4W5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.073",
            "price": "$109",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CMKWMNCP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.120",
            "price": "$128",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08T3PBV57?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.124",
            "price": "$73",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B079TL4TDJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.166",
            "price": "$110",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H28SY36?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.166",
            "price": "$110",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CGKH6NPJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.166",
            "price": "$110",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CMK6CP7L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.180",
            "price": "$73",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B074WGLH47?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.188",
            "price": "$74",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08W1PRTNJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.194",
            "price": "$110",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6QTP2WR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.249",
            "price": "$74",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MCVVGB9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.282",
            "price": "$130",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BRJXNK2S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.374",
            "price": "$150",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CX85RTP4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.375",
            "price": "$75",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07CJLK8TX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.379",
            "price": "$469",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00YT6XGZM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.436",
            "price": "$24",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CNN2BV8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.438",
            "price": "$118",
            "capacity": "2.5 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07JKQHGFR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.440",
            "price": "$118",
            "capacity": "2.5 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JKQHGFR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.467",
            "price": "$142",
            "capacity": "1.5 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003MAOU8S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.562",
            "price": "$76",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08Z77QT43?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.582",
            "price": "$115",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08V5HRWNQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.582",
            "price": "$115",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B074JJ2LDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.583",
            "price": "$575",
            "capacity": "12 TB x5",
            "warranty": "2 years",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BLDVB2RP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.600",
            "price": "$480",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CNN1VFA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.600",
            "price": "$480",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AKYY56Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.600",
            "price": "$24",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AR3DQRY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.600",
            "price": "$29",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005WSXR3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.686",
            "price": "$77",
            "capacity": "800 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003ZWVKSG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.687",
            "price": "$155",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08K9964SJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.800",
            "price": "$490",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00H5AH76I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.802",
            "price": "$137",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08B1X3W7D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.840",
            "price": "$246",
            "capacity": "2.5 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6Y87FC1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.875",
            "price": "$158",
            "capacity": "16 TB",
            "warranty": "1 month",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CXRW8VQL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.928",
            "price": "$139",
            "capacity": "14 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6QTC2K3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.940",
            "price": "$25",
            "capacity": "2.5 TB",
            "warranty": "3 months",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AZTUDGC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.944",
            "price": "$179",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C2S8B956?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.944",
            "price": "$179",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C2RLBQRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.967",
            "price": "$15",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003HKLHZC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.968",
            "price": "$60",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01BLFWKOG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.989",
            "price": "$499",
            "capacity": "2.5 TB x20",
            "warranty": "30 years",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AHQUVVA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9.994",
            "price": "$125",
            "capacity": "2.5 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00MRGG9XW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$100",
            "capacity": "10 TB",
            "warranty": "3 months",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C9PK4RXD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$75",
            "capacity": "1.5 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRX3GZY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$80",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07TVFJ825?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$500",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07KRYVYRS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$100",
            "capacity": "10 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BS91MMBG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$140",
            "capacity": "14 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CN423BJJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$160",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6QSS4T3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$160",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CP8VCTTJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$160",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CMGHLL9T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$160",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CXCCBM61?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$15",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003H8V82G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$125",
            "capacity": "2.5 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NUT7WT0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$25",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00ARHKUZG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$60",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00T7MB85I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$20",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0056JZ596?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$30",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0082D58L8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.00",
            "price": "$40",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01CG0DCJM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.16",
            "price": "$61",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B017OUMXWQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.19",
            "price": "$153",
            "capacity": "1.5 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003HLFF7C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.31",
            "price": "$165",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C8RR7DY4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.33",
            "price": "$124",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084WLXWXD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.37",
            "price": "$166",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08DHHMZBF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.40",
            "price": "$26",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AAHS9YW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.40",
            "price": "$26",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01MQV6BBA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.42",
            "price": "$125",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07NVSV5KD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.49",
            "price": "$63",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B019JJOPZI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.56",
            "price": "$169",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08BW9779X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.56",
            "price": "$169",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09HVGDBYB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.56",
            "price": "$169",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BC2GRDLQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.56",
            "price": "$169",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6QTP7J7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.57",
            "price": "$32",
            "capacity": "3 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07F7NBGY3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.59",
            "price": "$85",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B014UYHCPM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.60",
            "price": "$530",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AZTUEXE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.62",
            "price": "$170",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C79VWQJF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.66",
            "price": "$64",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0141MFX0O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.71",
            "price": "$150",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08M9SHX2M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.71",
            "price": "$150",
            "capacity": "14 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJLLTJXV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.71",
            "price": "$150",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CT1JP6QT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.74",
            "price": "$43",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01N9HD4VA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.82",
            "price": "$65",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00JJIEH3U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$130",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B082YJHBHC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$130",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084QBB99H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$650",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0743J2WJQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$130",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6QSK6S3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$130",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJM1B6DY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$130",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CYSQ7YMN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$195",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CH1PMX7Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.83",
            "price": "$195",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09RYLD7GL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.90",
            "price": "$109",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07R6P78KL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.94",
            "price": "$175",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08H1T666Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$10.94",
            "price": "$175",
            "capacity": "16 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CW3T1SKY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.00",
            "price": "$110",
            "capacity": "10 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJL3QTCL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.00",
            "price": "$220",
            "capacity": "20 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJL7KC27?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.05",
            "price": "$199",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CSF2BZYC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.05",
            "price": "$33",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07X81MKZG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.06",
            "price": "$199",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C8BWDSB7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.06",
            "price": "$199",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C8BWL98H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.09",
            "price": "$200",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C22VHG1S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.11",
            "price": "$200",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09NP4Y2JC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.12",
            "price": "$89",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B086W38F5Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.14",
            "price": "$334",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CIQK80G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.17",
            "price": "$134",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07V1ZTPRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.17",
            "price": "$134",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BPN5F9WG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.18",
            "price": "$45",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01AV1697A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.18",
            "price": "$45",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07WC4WJBP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.24",
            "price": "$90",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GSJ7M81?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.25",
            "price": "$90",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09FDKJTP6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.25",
            "price": "$9",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000RA1N4U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.25",
            "price": "$45",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00EU6JDAI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.30",
            "price": "$17",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003CNOSBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.33",
            "price": "$17",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003CNOSBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.35",
            "price": "$136",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07TVF622D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.43",
            "price": "$160",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084X87F51?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.43",
            "price": "$160",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MWBZD8L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.43",
            "price": "$160",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B086W2V5PF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.50",
            "price": "$230",
            "capacity": "20 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BYTVJT4Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.58",
            "price": "$139",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B075CPT95M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.60",
            "price": "$116",
            "capacity": "10 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CP8VLNDM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.60",
            "price": "$17",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J2AQMQU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.62",
            "price": "$93",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CG567SYG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.66",
            "price": "$70",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01DLDQJOS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.67",
            "price": "$140",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B075XNQYV1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.67",
            "price": "$210",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08H2HSN3Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.67",
            "price": "$210",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CZM957F3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.67",
            "price": "$350",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01DJSN254?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.80",
            "price": "$354",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01JN8DQIW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.81",
            "price": "$189",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07T3VQC2L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.81",
            "price": "$189",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BFNMNFYF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.87",
            "price": "$95",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H289S7C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.88",
            "price": "$95",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07N7FKJ2J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.90",
            "price": "$18",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005JZDKXK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.91",
            "price": "$119",
            "capacity": "10 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01GXUR98G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.93",
            "price": "$18",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003F8JGPO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11.93",
            "price": "$18",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TXUANR8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.00",
            "price": "$120",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01IA9H22Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.00",
            "price": "$96",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07JKJ4J93?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.00",
            "price": "$30",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00WUCTBOW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.00",
            "price": "$48",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009YQOL9C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.12",
            "price": "$97",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09P2JY17H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.22",
            "price": "$220",
            "capacity": "18 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08DHH8V9P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.22",
            "price": "$220",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CXBVV1SW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.25",
            "price": "$18",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003Z4QAMU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.28",
            "price": "$172",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DHY61JP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.31",
            "price": "$98",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08JLKHRRR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.36",
            "price": "$99",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097CY9Z6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.36",
            "price": "$99",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097CTNJDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.36",
            "price": "$99",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097CWJDP5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.37",
            "price": "$124",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01FT191XG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.44",
            "price": "$10",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000VIBOUQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.44",
            "price": "$50",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00EU6JDAI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.49",
            "price": "$50",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084GF686M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.50",
            "price": "$100",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H24QRS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.50",
            "price": "$250",
            "capacity": "20 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CHKP1716?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.50",
            "price": "$1,250",
            "capacity": "20 TB x5",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CKS32PTN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.50",
            "price": "$10",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000R97YRQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.50",
            "price": "$25",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B004VFJ9MK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.51",
            "price": "$100",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B014UYHJWS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.51",
            "price": "$100",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B014UYHIEC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.57",
            "price": "$50",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00VN08ZRG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.57",
            "price": "$176",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07KPL474H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.59",
            "price": "$101",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073JF992B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.62",
            "price": "$151",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09S15DB8Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.63",
            "price": "$19",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00PV1LTXU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.65",
            "price": "$10",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000QRV3YE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.67",
            "price": "$19",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00OGPHZ8A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.68",
            "price": "$152",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0759Q9FXZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.70",
            "price": "$32",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00INAJA7I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.73",
            "price": "$127",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XK2FXPT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.75",
            "price": "$102",
            "capacity": "800 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002Y352YK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.77",
            "price": "$2",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01K8TVEY8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.81",
            "price": "$205",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09DR3NQDF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.87",
            "price": "$206",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002GCT344?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.87",
            "price": "$129",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09213S6QC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12.98",
            "price": "$390",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003IIVVRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.00",
            "price": "$208",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00C0UMOM0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.00",
            "price": "$39",
            "capacity": "3 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009YQOIYA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.00",
            "price": "$39",
            "capacity": "3 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07Y8TVTLR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.03",
            "price": "$391",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00V2PSG0I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.06",
            "price": "$235",
            "capacity": "18 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CRHRXYMQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.12",
            "price": "$7",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B008RZLSWE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.13",
            "price": "$105",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B071VRCYXK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.16",
            "price": "$79",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01AROO7WU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.17",
            "price": "$20",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008436RKW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.18",
            "price": "$105",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00SNGZB34?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.30",
            "price": "$20",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003F8JH6C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.30",
            "price": "$106",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CMQ8XBBR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.31",
            "price": "$20",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003F8HDZO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$40",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00D7IMI4I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$40",
            "capacity": "3 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07KBC4HH6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$40",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07X81MKZG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$160",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D961VDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$160",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B072C75QMX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$800",
            "capacity": "12 TB x5",
            "warranty": "1 year",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09C49HTH9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$40",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005WSXR3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.33",
            "price": "$40",
            "capacity": "3 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004TLVKLO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.36",
            "price": "$53",
            "capacity": "800 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HRXDFGY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.43",
            "price": "$188",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07NBNHZ8G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.48",
            "price": "$108",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00NP6AOCK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.54",
            "price": "$81",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09NCMMSQX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.61",
            "price": "$109",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09YLH2TKM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.64",
            "price": "$191",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08M9SHX2M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.66",
            "price": "$11",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002FTKX6U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.67",
            "price": "$410",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003CNXO8W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.67",
            "price": "$82",
            "capacity": "6 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JJIE8E8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.75",
            "price": "$55",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0090UGQ2C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.75",
            "price": "$110",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CMQ8XBBR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.80",
            "price": "$248",
            "capacity": "18 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08KTQWV7Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.90",
            "price": "$222",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CKVG8P2M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13.97",
            "price": "$112",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01N0D7WEP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$168",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0852BMJ68?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$140",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01DSRHFOK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$210",
            "capacity": "1.5 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003MAOUGA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$420",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003F8OKU0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$28",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00A47FS92?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$21",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01EXDKWAM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.00",
            "price": "$140",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01MQZAQDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.10",
            "price": "$113",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H4PR6HN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.12",
            "price": "$113",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B075WYBQXJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.17",
            "price": "$170",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H241VJY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.22",
            "price": "$36",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VLZVD4L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.25",
            "price": "$28",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0082D57ZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.28",
            "price": "$200",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GTGDZP8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.28",
            "price": "$200",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H7CKYGT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.28",
            "price": "$200",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07K2XBM66?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.30",
            "price": "$86",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0845SMQJJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.31",
            "price": "$229",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08H18YG3R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.33",
            "price": "$430",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B014JWMFWU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.36",
            "price": "$11",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00J0N3K8C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.36",
            "price": "$11",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WRP1LQB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.36",
            "price": "$11",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WWLG519?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.37",
            "price": "$115",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H289S7C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.37",
            "price": "$230",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SJTST6T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.37",
            "price": "$230",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07W1YLQSZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.40",
            "price": "$180",
            "capacity": "2.5 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B015TR8A32?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.42",
            "price": "$173",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07N1765S8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.54",
            "price": "$320",
            "capacity": "22 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C9V6BCVB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.68",
            "price": "$59",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B079RZHLDL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.71",
            "price": "$118",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09KMGQG5Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.76",
            "price": "$44",
            "capacity": "3 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0100ZJEKG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.90",
            "price": "$6",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000FSL97U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.91",
            "price": "$1,789",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B017OUMZC4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.95",
            "price": "$15",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00B6TMDJA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.96",
            "price": "$60",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B087QTVCHH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.97",
            "price": "$60",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MC9JT41?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.98",
            "price": "$90",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GFKM5NG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.99",
            "price": "$12",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BMQ52N6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14.99",
            "price": "$210",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09C48XCWP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$30",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009CPDI62?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$30",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07V2BLGKT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$30",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00JRUW66A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$30",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00BRER4G6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$30",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07KJW4GSL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$60",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HMLZBFG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$60",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00PDDC2G4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$90",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07PMFRRT5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$90",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GBFWRDD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$210",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H4VBRKW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$240",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07T3VQC2L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$60",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00CVT9UH2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.00",
            "price": "$750",
            "capacity": "2.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C41R3FP8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.15",
            "price": "$30",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HB64M7N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.19",
            "price": "$243",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003ZX2JGM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.30",
            "price": "$14",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009QO888W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.33",
            "price": "$46",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009L5I5PC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.39",
            "price": "$123",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B075WYBQXJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.52",
            "price": "$186",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP4BMQ2G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.56",
            "price": "$249",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09B3W527D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.60",
            "price": "$39",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AYB5QWC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.62",
            "price": "$125",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00X8Z77W8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.62",
            "price": "$250",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0832BL1HC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.64",
            "price": "$219",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H4VBRKW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.64",
            "price": "$219",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H7CW4YT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.69",
            "price": "$251",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0019UAH7Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.71",
            "price": "$126",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LQQHLGC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.74",
            "price": "$63",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009YQOL9C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.76",
            "price": "$32",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00VSUY944?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.80",
            "price": "$79",
            "capacity": "25 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K6T1QJO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.80",
            "price": "$126",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B092R5KTT7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.81",
            "price": "$63",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0792G331G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.83",
            "price": "$285",
            "capacity": "18 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DHH8V9P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.83",
            "price": "$95",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01E1KQDBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.93",
            "price": "$24",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HXGRSFE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$15.97",
            "price": "$24",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00N3B6V1U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.00",
            "price": "$60",
            "capacity": "25 GB x150",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K5D1EGG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.12",
            "price": "$129",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07CBM8P89?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.16",
            "price": "$97",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07235S2HH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.19",
            "price": "$97",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B013JPKYQC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.22",
            "price": "$292",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093Z5LFWD?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.22",
            "price": "$65",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01FRC1AJ0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.24",
            "price": "$13",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B010EIP0Y8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$65",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H8NBQ44?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$130",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XS423SC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$260",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007WW8SLM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$260",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09DR3NQDF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$260",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08H1T666Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$195",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0852BMJ68?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.25",
            "price": "$41",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KVLRU6A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.27",
            "price": "$325",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09MWKN3RN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.32",
            "price": "$163",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01M07WKSE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.33",
            "price": "$131",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084ZV4DXB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.33",
            "price": "$327",
            "capacity": "20 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09VCXWPQG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.35",
            "price": "$327",
            "capacity": "20 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09VCXWPQG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.38",
            "price": "$131",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B094V34SHR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.39",
            "price": "$20",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K5D1C2M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.47",
            "price": "$247",
            "capacity": "25 GB x600",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TSG1PRT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.50",
            "price": "$33",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0057BOO4A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.50",
            "price": "$66",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BS8K1P6W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.50",
            "price": "$165",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01DSRHFOK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.56",
            "price": "$232",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GTGDZP8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.62",
            "price": "$133",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01FT19136?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.62",
            "price": "$266",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01AQAOPPO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.65",
            "price": "$10",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0038JVJCS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.65",
            "price": "$100",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00X2YO90I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.66",
            "price": "$25",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003F8MT5I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.66",
            "price": "$50",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005T3GRLY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.66",
            "price": "$100",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00M0256UA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.67",
            "price": "$200",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B075L6FJH8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.67",
            "price": "$300",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KY32HFR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.67",
            "price": "$2,000",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B017OUMMA4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.67",
            "price": "$50",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0100ZO4S8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.72",
            "price": "$67",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B1JPYH2Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.75",
            "price": "$67",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00H4IAGM8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.76",
            "price": "$67",
            "capacity": "800 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B017AIIM8Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.81",
            "price": "$269",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFNMNFYF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.81",
            "price": "$269",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W1YLQSZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.84",
            "price": "$135",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CMQ6SK7W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.87",
            "price": "$270",
            "capacity": "16 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SZT2LBQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.87",
            "price": "$270",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SYPFNY4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.94",
            "price": "$271",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SJTST6T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.95",
            "price": "$339",
            "capacity": "20 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BMQWLCCM?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$16.98",
            "price": "$7",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00069DRG4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.00",
            "price": "$14",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001TOAYCU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.10",
            "price": "$86",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07X41PWTY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.12",
            "price": "$21",
            "capacity": "25 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DM6WYZ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.14",
            "price": "$240",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B082LTQG89?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.14",
            "price": "$240",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H7CKYGT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.17",
            "price": "$26",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00P9WH2OQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.19",
            "price": "$43",
            "capacity": "2.5 TB",
            "warranty": "2 years",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XTRVDTB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.19",
            "price": "$21",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00LV8BWPM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.20",
            "price": "$241",
            "capacity": "14 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B088PBY844?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.30",
            "price": "$26",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003IAEAR8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.33",
            "price": "$104",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B071V6SVK2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.38",
            "price": "$139",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B094V34SHR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.42",
            "price": "$313",
            "capacity": "18 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09MWKFG51?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.44",
            "price": "$14",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000QRRNFM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.44",
            "price": "$279",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0832BL1HC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.46",
            "price": "$244",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BRJXNK2S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.47",
            "price": "$70",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00E7LTBBW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$35",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07C5WVQ8S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$210",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TQ7VT42?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$280",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08BW9779X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$280",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08H18YG3R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$210",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D961VDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$70",
            "capacity": "800 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KSKSUG8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$175",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07S1D53YN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$35",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003IT70K8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.50",
            "price": "$280",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093BWYJ9Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.56",
            "price": "$22",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00AXYQGKG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.60",
            "price": "$106",
            "capacity": "6 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MYL7KVK?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.62",
            "price": "$141",
            "capacity": "800 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0721NSL4Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.64",
            "price": "$106",
            "capacity": "6 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BDXQ61Z9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.66",
            "price": "$22",
            "capacity": "25 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00IK2OQM8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.66",
            "price": "$53",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B008JJLW4M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.75",
            "price": "$71",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XL6WHMM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.79",
            "price": "$249",
            "capacity": "14 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07Y3KDVZH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.81",
            "price": "$285",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07L28XQ2V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.82",
            "price": "$178",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0966TZJGJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.83",
            "price": "$107",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B013JPKYQC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.86",
            "price": "$250",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DHY61JP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.90",
            "price": "$1,790",
            "capacity": "20 TB x5",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CKS1YC21?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.92",
            "price": "$430",
            "capacity": "24 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CSPCFKR9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.96",
            "price": "$108",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LQQHKZY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17.99",
            "price": "$72",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00H4IAGM8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$45",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0788SKQRN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$252",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XC95Y28?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$360",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TBF6GHJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$180",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07CRZK9BX?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$45",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N068YXE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$45",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0788SKQRN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.00",
            "price": "$90",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VP5X239?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.01",
            "price": "$252",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H7CW4YT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.06",
            "price": "$325",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08JV6PP9B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.22",
            "price": "$219",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B086WVVXCS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.25",
            "price": "$365",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CLT791HQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.28",
            "price": "$46",
            "capacity": "25 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TYQJSD5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.32",
            "price": "$55",
            "capacity": "3 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009YQOIYA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.32",
            "price": "$293",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SHWSTZ2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.33",
            "price": "$220",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DNVC4S6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.33",
            "price": "$110",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01E1KQDBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.33",
            "price": "$330",
            "capacity": "18 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08K98VFXT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.33",
            "price": "$440",
            "capacity": "24 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP81HTNX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.38",
            "price": "$147",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B014UYHJWS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.40",
            "price": "$221",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DNVC4S6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.41",
            "price": "$331",
            "capacity": "18 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KTQWV7Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.44",
            "price": "$295",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DHHMZBF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.45",
            "price": "$406",
            "capacity": "22 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BTFPTSNQ?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.45",
            "price": "$369",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09MWKN3RN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.49",
            "price": "$37",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B008YN6QMQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.50",
            "price": "$37",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00DXOJJQQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.50",
            "price": "$74",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01N2TP0ZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.50",
            "price": "$370",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BGFT5WBP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.50",
            "price": "$185",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D9627NB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.62",
            "price": "$149",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09KMGQG5Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.62",
            "price": "$149",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B014UYHCPM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.64",
            "price": "$410",
            "capacity": "22 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BTFPTSNQ?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.67",
            "price": "$224",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08V1L1WYD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.68",
            "price": "$149",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07LFPNRPG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.68",
            "price": "$112",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00M0256UA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.74",
            "price": "$150",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B5JLNJY9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.74",
            "price": "$150",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09SBL7X1W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$75",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00TP5J9FS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$75",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00PDDC2G4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$150",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01M1BUBSO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$150",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D962J5R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$150",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07W4SL9C6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$300",
            "capacity": "16 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08Y1QYWR5?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$300",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KTMVWNZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$75",
            "capacity": "800 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00EK3HTC0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$150",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H4PR6HN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.75",
            "price": "$225",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08FCWWV44?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.81",
            "price": "$301",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08K3VVKSW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.82",
            "price": "$188",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07R6P78KL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.83",
            "price": "$113",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B099Q4QP4T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.86",
            "price": "$75",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C7FPRSK3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.93",
            "price": "$265",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08MBCX8RW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$18.96",
            "price": "$417",
            "capacity": "22 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B94NBBJH?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.00",
            "price": "$304",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08K3VVKSW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.01",
            "price": "$228",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H241VJY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.17",
            "price": "$115",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01CG0DBXE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.17",
            "price": "$230",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0759Q9FXZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.20",
            "price": "$77",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VN08ZRG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.21",
            "price": "$231",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0966V6YJB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.21",
            "price": "$269",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0966SWW5S?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.22",
            "price": "$77",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007VL8WU6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.25",
            "price": "$116",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01E1KQF26?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.25",
            "price": "$154",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00T76CAMY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.28",
            "price": "$270",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CFW39VH8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.31",
            "price": "$309",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TQ6ZZPV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.36",
            "price": "$310",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09B3W527D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.37",
            "price": "$155",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01HD6ZLQ6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.48",
            "price": "$39",
            "capacity": "400 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00DCEDQ98?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.50",
            "price": "$39",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00DXOJJQQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.50",
            "price": "$390",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BGFT5WBP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.54",
            "price": "$430",
            "capacity": "22 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BB8TMWV7?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.61",
            "price": "$353",
            "capacity": "18 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B92GTRVY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.61",
            "price": "$431",
            "capacity": "22 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B94NBBJH?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.63",
            "price": "$79",
            "capacity": "400 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003LMHSRW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.64",
            "price": "$275",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07KPL474H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.64",
            "price": "$275",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07K2XBM66?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.68",
            "price": "$315",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B099P8VGMY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.69",
            "price": "$16",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TXRSUHG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.71",
            "price": "$315",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WTQDJC5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.75",
            "price": "$79",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B089Q7J25S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.75",
            "price": "$99",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07X46C64N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.75",
            "price": "$16",
            "capacity": "800 GB",
            "warranty": "3 months",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01C1I6PQ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.75",
            "price": "$40",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D9Y8R93?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.78",
            "price": "$198",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01IA9H22Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.79",
            "price": "$277",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YFGW736?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.79",
            "price": "$20",
            "capacity": "1 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07F7NYPSQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.80",
            "price": "$317",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CY3GKSG3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.80",
            "price": "$198",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D9627NB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.87",
            "price": "$159",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B099PC1CDN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.88",
            "price": "$159",
            "capacity": "800 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0030EPF5I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.88",
            "price": "$159",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09SBL7X1W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.89",
            "price": "$20",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00512JC5G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.90",
            "price": "$159",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B014UYHIEC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.90",
            "price": "$995",
            "capacity": "10 TB x5",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06VY9QRZB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.90",
            "price": "$199",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01MQZAQDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.93",
            "price": "$279",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07W6Y6JWR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.96",
            "price": "$30",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B006Y8QJMK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19.99",
            "price": "$20",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005T3GRNW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$80",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00E7LTBBW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$40",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007VL8WS8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$40",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B9F37KQ9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$40",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VSUY944?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$50",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CLUU7I2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$80",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H2FPFW5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$80",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00FW3RM9M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$120",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CTGZ7P4G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$150",
            "capacity": "25 GB x300",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TXRV971?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$160",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B092R5KTT7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$160",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XQ18JJW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$160",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CMQ6SK7W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$200",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VBZYV1C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$200",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07G64YFML?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$200",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01I4Q9FTI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$240",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09DVPV5P9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$320",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CY3GKSG3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$480",
            "capacity": "24 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CM293XCL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$8",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0006GDC3U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$600",
            "capacity": "1.5 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003IIVVRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.00",
            "price": "$16",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00UD77XS2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.02",
            "price": "$360",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093BTVWPH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.24",
            "price": "$162",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D3N95GS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.33",
            "price": "$20",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007X15MNO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.39",
            "price": "$163",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QQX27GM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.42",
            "price": "$327",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CT9GQZC1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.42",
            "price": "$368",
            "capacity": "18 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08J6RZ4XN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.50",
            "price": "$246",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07N8WTFBY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.50",
            "price": "$492",
            "capacity": "24 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CT1JRLVJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.55",
            "price": "$288",
            "capacity": "14 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Y3KDVZH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.56",
            "price": "$329",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TQ8FYN2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.66",
            "price": "$124",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01CG0DCEW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.72",
            "price": "$166",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CJLK8TX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.72",
            "price": "$249",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWSZJ78W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.75",
            "price": "$166",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BD64HS6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.81",
            "price": "$333",
            "capacity": "800 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HCZD51M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.83",
            "price": "$250",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VXKF1L4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.83",
            "price": "$42",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08VH8R94B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.83",
            "price": "$250",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BWSZJ78W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.83",
            "price": "$250",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B074JHYMKF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.83",
            "price": "$125",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01BBEQD68?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.83",
            "price": "$125",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0826B9ZH5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.85",
            "price": "$208",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M07WKSE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20.88",
            "price": "$376",
            "capacity": "18 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09MWKFG51?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.00",
            "price": "$84",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B007VL8WU6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.00",
            "price": "$42",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0056JZ596?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.03",
            "price": "$463",
            "capacity": "22 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C3B27CYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.05",
            "price": "$337",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KY99GPL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.09",
            "price": "$169",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H24QRS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.11",
            "price": "$19",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00CGC1VBC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.12",
            "price": "$169",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SW18G1K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.19",
            "price": "$254",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WF8LQL5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.20",
            "price": "$11",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00II56U0I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.20",
            "price": "$339",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KY4P7L5?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.25",
            "price": "$85",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LOOJBQY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.25",
            "price": "$85",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XL6WHMM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.25",
            "price": "$170",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LQQHLGC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.25",
            "price": "$170",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CBM8P89?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.29",
            "price": "$255",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B086WVVXCS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.29",
            "price": "$64",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CGZ3SPDC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.33",
            "price": "$107",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VNTFHD5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.36",
            "price": "$171",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CP314RM7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.38",
            "price": "$171",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B019OSCLH8?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.42",
            "price": "$128",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09NCMMSQX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.50",
            "price": "$86",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B013P0JGEW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.52",
            "price": "$301",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09C48XCWP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.53",
            "price": "$43",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07CRG94G3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.60",
            "price": "$108",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MY44VNM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.62",
            "price": "$65",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00B229W04?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.62",
            "price": "$260",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RQ99XJH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.64",
            "price": "$303",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0966SWW5S?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.66",
            "price": "$130",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01M5FSTXY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.67",
            "price": "$260",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07W7YBB9H?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.67",
            "price": "$260",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0966V6YJB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.67",
            "price": "$65",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0100ZO4S8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.70",
            "price": "$174",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08XNL4CRM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.71",
            "price": "$304",
            "capacity": "14 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082LTQG89?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.72",
            "price": "$174",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MC5GKG5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.83",
            "price": "$262",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074JJ2LDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.86",
            "price": "$175",
            "capacity": "800 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HCZD2QK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.87",
            "price": "$175",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XVB7G99?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.87",
            "price": "$350",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08899GVT6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.87",
            "price": "$350",
            "capacity": "8 TB x2",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CNVYQX5W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.88",
            "price": "$7",
            "capacity": "320 GB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00DSUTVYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.88",
            "price": "$306",
            "capacity": "14 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Y3JXGPL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.91",
            "price": "$110",
            "capacity": "5 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08Y1S6R1V?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.92",
            "price": "$132",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JJIEH3U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.97",
            "price": "$44",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0167AG8DO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21.99",
            "price": "$33",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00PB50NQ0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.00",
            "price": "$176",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M1BUBSO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.00",
            "price": "$176",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XQ18JJW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.00",
            "price": "$28",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0094D2AP2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.00",
            "price": "$28",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J2AXG8C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.00",
            "price": "$9",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00084O3SS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.17",
            "price": "$399",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08H2HSN3Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.18",
            "price": "$266",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W7YBB9H?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.24",
            "price": "$178",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QQX27GM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.24",
            "price": "$267",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WR2H33J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.25",
            "price": "$178",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W4SL9C6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.32",
            "price": "$67",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005T3GRLY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.37",
            "price": "$179",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084ZV4DXB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.38",
            "price": "$9",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0065P34BQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.38",
            "price": "$9",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002U33U48?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.38",
            "price": "$9",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009R5R7K0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.40",
            "price": "$224",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GHMTK9P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.44",
            "price": "$18",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000QEITB2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.46",
            "price": "$494",
            "capacity": "22 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWSYJZ29?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.48",
            "price": "$9",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0006G36UO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.49",
            "price": "$90",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01FRC1AJ0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.50",
            "price": "$90",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B1JPYH2Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.50",
            "price": "$180",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RRCQVN1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.50",
            "price": "$180",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09NCNGBDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.50",
            "price": "$450",
            "capacity": "20 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09TBF6GHJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.50",
            "price": "$360",
            "capacity": "800 GB x20",
            "warranty": "1 month",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJJZQ8DK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.58",
            "price": "$271",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08V1L1WYD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.67",
            "price": "$113",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XQXF444?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.68",
            "price": "$181",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N0D7WEP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.75",
            "price": "$273",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07N1765S8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.79",
            "price": "$273",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B075CPT95M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.79",
            "price": "$91",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GKBT14V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.82",
            "price": "$228",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XK2FXPT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.83",
            "price": "$68",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08JYNF1NC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.90",
            "price": "$183",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07FNK6QMT?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.99",
            "price": "$21",
            "capacity": "900 GB",
            "warranty": "1 month",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08QJPTG5Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.99",
            "price": "$46",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JRUW66A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22.99",
            "price": "$34",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0057HJP1G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.00",
            "price": "$92",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B086Q3RJF2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.00",
            "price": "$46",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07QJGVZD6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.00",
            "price": "$23",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KQ4Q70C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.02",
            "price": "$115",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B079HGSFY5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.06",
            "price": "$369",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B099P9YDBD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.08",
            "price": "$92",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B087QTVCHH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.11",
            "price": "$185",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SW18G1K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.12",
            "price": "$139",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00T7MB85I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.12",
            "price": "$370",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085ZZTL7B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.16",
            "price": "$29",
            "capacity": "25 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IK2OQM8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.16",
            "price": "$139",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00X2YO90I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.32",
            "price": "$70",
            "capacity": "3 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083XVD1FP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.33",
            "price": "$140",
            "capacity": "6 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDXQ61Z9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.33",
            "price": "$280",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B075L6FJH8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.33",
            "price": "$21",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00C9TEPHE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.52",
            "price": "$94",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0796J427H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.58",
            "price": "$189",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BXGGD69P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.60",
            "price": "$118",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VNTFHD5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.61",
            "price": "$94",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01AV1697A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.67",
            "price": "$71",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0082D58L8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.70",
            "price": "$237",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0966TZJGJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$380",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08899GVT6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$190",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XVB7G99?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$10",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01AQ3M8KK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$95",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N2TP0ZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$285",
            "capacity": "12 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SXVQ5WD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$95",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07T4411LF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.75",
            "price": "$95",
            "capacity": "4 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BW4Y3SFH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.92",
            "price": "$30",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AYX7VRS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.94",
            "price": "$96",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01HD6ZLIY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.95",
            "price": "$24",
            "capacity": "1 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HBDF4T3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.95",
            "price": "$479",
            "capacity": "20 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XSYVR33?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.96",
            "price": "$30",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AXYQGKG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.96",
            "price": "$30",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009KXE4VO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$23.96",
            "price": "$30",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IK46BJC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.00",
            "price": "$120",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VP5X239?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.00",
            "price": "$96",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B076826S5R?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.00",
            "price": "$24",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B008DJE7HW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.10",
            "price": "$48",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BRER4G6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.10",
            "price": "$386",
            "capacity": "16 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B088S9PWNM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.13",
            "price": "$48",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GKK35BT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.14",
            "price": "$97",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B076826S5R?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.16",
            "price": "$145",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LQQHKZY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.16",
            "price": "$145",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01K4241D0?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.17",
            "price": "$290",
            "capacity": "12 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VXKF1L4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.22",
            "price": "$145",
            "capacity": "6 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B092R599W5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.27",
            "price": "$29",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00B96LSDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.37",
            "price": "$195",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07NTDWDGL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.38",
            "price": "$195",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08XNL4CRM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.38",
            "price": "$20",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00JPC2V7E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.38",
            "price": "$195",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VO841AC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.56",
            "price": "$295",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BB8TPT2W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.60",
            "price": "$123",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07X41PWTY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.60",
            "price": "$98",
            "capacity": "800 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HCZD17K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.64",
            "price": "$345",
            "capacity": "14 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W6Y6JWR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.67",
            "price": "$592",
            "capacity": "24 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CNT1RNZ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.70",
            "price": "$247",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01I4Q9FTI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.70",
            "price": "$10",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000FSL97U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.72",
            "price": "$99",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LOOJBQY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.75",
            "price": "$99",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B148JDG2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.75",
            "price": "$99",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZGK7ZPF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.77",
            "price": "$124",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B088P3KZZ9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.80",
            "price": "$545",
            "capacity": "22 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BB8TMWV7?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.83",
            "price": "$149",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01BBEQD68?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.83",
            "price": "$15",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07K8RXJJ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.88",
            "price": "$10",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00B416MYM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.88",
            "price": "$10",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005D9I9Q0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.88",
            "price": "$20",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003PODVUO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.88",
            "price": "$199",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09FH97SDT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.88",
            "price": "$199",
            "capacity": "8 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077XZWJNZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.88",
            "price": "$199",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09B3VGD6F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.94",
            "price": "$20",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000Q061YS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.94",
            "price": "$399",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CHM7PJZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.95",
            "price": "$25",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00A0KXK7U?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.95",
            "price": "$25",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00J4USXF6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.98",
            "price": "$50",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00QJA931C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.98",
            "price": "$100",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083XVY99B?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.98",
            "price": "$25",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B006IMFT32?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.99",
            "price": "$20",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000RA6TKI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$24.99",
            "price": "$30",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XR6KDQ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$100",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00TKFEE8K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$100",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B013P0JGEW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$100",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CVT9UH2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$100",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C7FPRSK3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$125",
            "capacity": "5 TB",
            "warranty": "1 month",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07S99JG85?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$200",
            "capacity": "8 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01HAPGEIE?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$200",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01HD6ZLQ6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$250",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08MKJPFZ7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$400",
            "capacity": "16 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B088S9PWNM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.00",
            "price": "$150",
            "capacity": "6 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MYL7KVK?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.02",
            "price": "$31",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B004477BQQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.07",
            "price": "$752",
            "capacity": "6 TB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01D4TCZUQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.32",
            "price": "$203",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00NP6ANY4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.33",
            "price": "$304",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B072C75QMX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.33",
            "price": "$152",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071V6SVK2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.39",
            "price": "$3,047",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01ABT6IFA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.50",
            "price": "$102",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W4QTBSF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.60",
            "price": "$16",
            "capacity": "25 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0060N4LRY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.67",
            "price": "$77",
            "capacity": "3 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01JKG99VU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.72",
            "price": "$154",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M0SH124?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.74",
            "price": "$412",
            "capacity": "8 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BHBWR3SV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.76",
            "price": "$3,091",
            "capacity": "6 TB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07FLJP9WK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.77",
            "price": "$258",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01FT191XG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.86",
            "price": "$129",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MY44VNM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.87",
            "price": "$207",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP314RM7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.90",
            "price": "$207",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LWVT81X?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.92",
            "price": "$311",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09DVPV5P9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.92",
            "price": "$78",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B006KCX0UE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.99",
            "price": "$26",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B006IMFT32?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.99",
            "price": "$39",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRZGFYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.99",
            "price": "$39",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRH41DK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.99",
            "price": "$39",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRH41DJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$25.99",
            "price": "$39",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRFW79J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.00",
            "price": "$26",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0088PUEPK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.20",
            "price": "$131",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M0AADIX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.39",
            "price": "$475",
            "capacity": "18 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KJMXRN7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.39",
            "price": "$370",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YFGW736?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.45",
            "price": "$212",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B099P9SMW8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.50",
            "price": "$66",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077YX78Z3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.50",
            "price": "$26",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B007X15MNO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.56",
            "price": "$13",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B001R4BBL0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.62",
            "price": "$213",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00T76CAMY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.66",
            "price": "$373",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07NBNHZ8G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.67",
            "price": "$320",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RQ99XJH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.70",
            "price": "$107",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CC6SB4F4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.71",
            "price": "$321",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07WR2H33J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.75",
            "price": "$374",
            "capacity": "14 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08JKBXLWF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.87",
            "price": "$215",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RRCQVN1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.95",
            "price": "$54",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00EG1TCPS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.98",
            "price": "$1,619",
            "capacity": "6 TB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08ZWSHRKL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.99",
            "price": "$108",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MY44QBT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$26.99",
            "price": "$27",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00724D7AM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.08",
            "price": "$217",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01NBPIWCM?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.11",
            "price": "$271",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VBZYV1C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.22",
            "price": "$109",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08ZJFH7Y1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.23",
            "price": "$136",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07X46C64N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.23",
            "price": "$136",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJG4PHDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.24",
            "price": "$82",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00B229W04?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.25",
            "price": "$109",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LXCE9W9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.37",
            "price": "$109",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0792G331G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.38",
            "price": "$11",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0006TLENW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.46",
            "price": "$329",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074JHYMKF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.48",
            "price": "$11",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0056333TG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.50",
            "price": "$550",
            "capacity": "20 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BCC45L6T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.50",
            "price": "$275",
            "capacity": "10 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0798DVKRV?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.50",
            "price": "$165",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07235S2HH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.60",
            "price": "$138",
            "capacity": "5 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B098B94D6K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.66",
            "price": "$166",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0796HJ2KZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.72",
            "price": "$388",
            "capacity": "14 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XC95Y28?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.81",
            "price": "$222",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D3N95GS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.90",
            "price": "$84",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B006KCX0UE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$27.98",
            "price": "$56",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B075QJTBVT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.00",
            "price": "$22",
            "capacity": "400 GB x2",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00304U2WY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.00",
            "price": "$14",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B007U1WRN0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.07",
            "price": "$449",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0858K9Y17?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.12",
            "price": "$450",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0858K9Y17?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.12",
            "price": "$225",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NP6AOCK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.12",
            "price": "$225",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00Q1IZJD8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.12",
            "price": "$450",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06WD4593G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.22",
            "price": "$56",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VSPL8FJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.32",
            "price": "$227",
            "capacity": "8 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073JF992B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.33",
            "price": "$340",
            "capacity": "12 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BB8TPT2W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.50",
            "price": "$57",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00EG1TCPS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.54",
            "price": "$29",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01C4W29XC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.60",
            "price": "$29",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07NGKRNVF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.60",
            "price": "$114",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00FJRS5BA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.66",
            "price": "$86",
            "capacity": "3 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDXQZTTV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.67",
            "price": "$86",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008JJLW4M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.74",
            "price": "$230",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D962J5R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.74",
            "price": "$230",
            "capacity": "400 GB x20",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002VCU2NA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.75",
            "price": "$230",
            "capacity": "8 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01HAPGEIE?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.75",
            "price": "$230",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MK3T28F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.80",
            "price": "$72",
            "capacity": "50 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K7KEW1G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.80",
            "price": "$144",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01M0AADIX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.82",
            "price": "$519",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B093Z5LFWD?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.83",
            "price": "$231",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00X8Z77W8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28.93",
            "price": "$58",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00IRV005E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.00",
            "price": "$174",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01CG0DBXE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.00",
            "price": "$29",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0767D1BZY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.07",
            "price": "$291",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073BLV4W5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.25",
            "price": "$58",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0167AG8DO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.33",
            "price": "$44",
            "capacity": "1.5 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002T3TLR4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.45",
            "price": "$118",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0796J427H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.64",
            "price": "$59",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00FJRS628?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.66",
            "price": "$148",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C9YLX21T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.74",
            "price": "$178",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TQ4DSRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.75",
            "price": "$119",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084ZV8YW8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.77",
            "price": "$298",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07S1D53YN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.79",
            "price": "$149",
            "capacity": "5 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00TKFEEBW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.87",
            "price": "$239",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09FH97SDT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.90",
            "price": "$299",
            "capacity": "10 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01GXUR98G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.90",
            "price": "$299",
            "capacity": "10 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07G64YFML?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.95",
            "price": "$60",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009CPDI62?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.98",
            "price": "$1,199",
            "capacity": "20 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QQPDHCH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$29.99",
            "price": "$150",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJG4PHDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.00",
            "price": "$60",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00A47FS92?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.00",
            "price": "$60",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B075QJTBVT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.00",
            "price": "$60",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07QSBG9S3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.00",
            "price": "$240",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0D1V2K4LJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.00",
            "price": "$18",
            "capacity": "600 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07FW6ZH6R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.00",
            "price": "$60",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0057BOO4A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.42",
            "price": "$487",
            "capacity": "8 TB x2",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CH219TF2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.50",
            "price": "$305",
            "capacity": "10 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GHMTK9P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.62",
            "price": "$245",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B019OSCLH8?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.70",
            "price": "$154",
            "capacity": "50 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TTKJ8V7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.83",
            "price": "$9",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00D02AHV2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.86",
            "price": "$1,111",
            "capacity": "18 TB x2",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CH216BP9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.94",
            "price": "$25",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0086880D2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.96",
            "price": "$155",
            "capacity": "5 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08Y1S6R1V?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$30.99",
            "price": "$31",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00A0KXK7U?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.12",
            "price": "$25",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B006FKEIZM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.19",
            "price": "$31",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00A47FPL8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.20",
            "price": "$156",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WW1RJ9R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.22",
            "price": "$31",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005T3GRNW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.24",
            "price": "$500",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08C8CHZTY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.25",
            "price": "$250",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MK3T28F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.25",
            "price": "$250",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09B3VGD6F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.25",
            "price": "$500",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09YR99DB1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.25",
            "price": "$500",
            "capacity": "16 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WHH3KL7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.25",
            "price": "$500",
            "capacity": "8 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073ZMWD98?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.33",
            "price": "$88",
            "capacity": "4.7 GB x600",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CS53SH9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.33",
            "price": "$47",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003CNLP06?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.33",
            "price": "$188",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M5FSTXY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.40",
            "price": "$78",
            "capacity": "50 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TSG43YP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.43",
            "price": "$39",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00GSQ4DBM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.46",
            "price": "$63",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B001UE8LRE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.50",
            "price": "$189",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077RV4ZLY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.66",
            "price": "$380",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082P6BCH1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.67",
            "price": "$38",
            "capacity": "1.2 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07FWKHB2W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.75",
            "price": "$127",
            "capacity": "800 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B014JWMJTY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.75",
            "price": "$64",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B088BZLL15?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.82",
            "price": "$64",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BN4B4L54?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.83",
            "price": "$30",
            "capacity": "4.7 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CS37CDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.84",
            "price": "$45",
            "capacity": "4.7 GB x300",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002IAKSMU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.87",
            "price": "$255",
            "capacity": "8 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B077XZWJNZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$31.98",
            "price": "$20",
            "capacity": "25 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MH3FRLT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.00",
            "price": "$160",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZLWLG2K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.00",
            "price": "$40",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00GSQ4DBM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.00",
            "price": "$64",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003IT70K8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.06",
            "price": "$385",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN5KRQKL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.19",
            "price": "$193",
            "capacity": "6 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B092R599W5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.22",
            "price": "$129",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TKFEE8K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.22",
            "price": "$129",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LXCE9W9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.38",
            "price": "$259",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B074LK3XHJ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.47",
            "price": "$49",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00V5HHOG0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.48",
            "price": "$1,299",
            "capacity": "20 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TWV7W2D?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.48",
            "price": "$32",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01C4W29XC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.50",
            "price": "$65",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VH8R94B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.50",
            "price": "$130",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JT8AJZ0?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.50",
            "price": "$130",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GKBT14V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.50",
            "price": "$1,300",
            "capacity": "20 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ8XX6XP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.50",
            "price": "$520",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KZKFN2Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.56",
            "price": "$65",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B078HTR1FM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.66",
            "price": "$49",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003HLTPBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.67",
            "price": "$49",
            "capacity": "1.5 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002T3TLR4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.69",
            "price": "$719",
            "capacity": "22 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CF7VF7HM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.76",
            "price": "$16",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001R4BBL0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.92",
            "price": "$165",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07K6MV64X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$32.99",
            "price": "$33",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00F7R07UE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.00",
            "price": "$33",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00A47FPL8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.17",
            "price": "$199",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01E1KQF26?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.20",
            "price": "$33",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LNJBA2I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.30",
            "price": "$33",
            "capacity": "100 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YZM8Y1N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.32",
            "price": "$20",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009OZ32OS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$200",
            "capacity": "6 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CX8QBG4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$200",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0796HJ2KZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$1,200",
            "capacity": "18 TB x2",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CH216BP9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$40",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0100ZPPQS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$20",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00K9S43XI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$40",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00B96LSDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.33",
            "price": "$40",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06ZXQRSX8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.48",
            "price": "$67",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B079BQS5WQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.56",
            "price": "$42",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IMS58LI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.61",
            "price": "$134",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N9HD4VA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.72",
            "price": "$20",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XL67LG4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.75",
            "price": "$68",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08JKGG97R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.78",
            "price": "$169",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079HGSFY5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.83",
            "price": "$203",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09FHB8HVB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.99",
            "price": "$34",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07NGTZWWZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$33.99",
            "price": "$34",
            "capacity": "1 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09F5KFHBD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.00",
            "price": "$68",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BQX6NNVC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.00",
            "price": "$170",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XQXF444?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.00",
            "price": "$170",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9YLX21T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.00",
            "price": "$42",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B018640FNI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.16",
            "price": "$205",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CVMYYJFQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.29",
            "price": "$549",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WTQDJC5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.37",
            "price": "$1,100",
            "capacity": "16 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ8XTB95?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.37",
            "price": "$550",
            "capacity": "16 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B088PBNDFH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.50",
            "price": "$69",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08736JJ62?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.50",
            "price": "$69",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B094QZLJQ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.50",
            "price": "$69",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CRG94G3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.56",
            "price": "$86",
            "capacity": "25 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079P9H9M4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.75",
            "price": "$139",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0716ZNGWR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.80",
            "price": "$44",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TWNYMC7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.88",
            "price": "$35",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08QTT1P5R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.93",
            "price": "$279",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NP6ANY4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$34.99",
            "price": "$35",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07NGKRNVF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.00",
            "price": "$140",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07W5XGYKP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.00",
            "price": "$140",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B086Q3RJF2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.00",
            "price": "$140",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B016J3E4K8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.14",
            "price": "$211",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01CG0DCEW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.25",
            "price": "$211",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0796J1HZ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.36",
            "price": "$17",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CS1DFQ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.48",
            "price": "$71",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XH2ZDXW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.54",
            "price": "$782",
            "capacity": "22 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C3B27CYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.58",
            "price": "$89",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AALKUSG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.71",
            "price": "$500",
            "capacity": "14 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B088PBY844?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.75",
            "price": "$143",
            "capacity": "400 GB x10",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08BW8HRN8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.75",
            "price": "$143",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083XVY99B?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.78",
            "price": "$4,580",
            "capacity": "16 TB x8",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084S3Y33G?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.78",
            "price": "$4,580",
            "capacity": "16 TB x8",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084S3ZY95?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.78",
            "price": "$215",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0141MFX0O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.80",
            "price": "$89",
            "capacity": "50 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00YBQT8XO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.80",
            "price": "$29",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00UW3HUMQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.90",
            "price": "$18",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00QFXOL5G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.93",
            "price": "$216",
            "capacity": "6 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07NGF65JP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.98",
            "price": "$90",
            "capacity": "50 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KOCVCP6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.98",
            "price": "$18",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000O78HRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35.99",
            "price": "$45",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079PBFM9V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.00",
            "price": "$18",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003SALVN4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.02",
            "price": "$288",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CGQW5MB9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.06",
            "price": "$6",
            "capacity": "160 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B001P80F10?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.11",
            "price": "$650",
            "capacity": "18 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B92GTRVY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.25",
            "price": "$1,450",
            "capacity": "20 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QQPDHCH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.28",
            "price": "$22",
            "capacity": "600 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00E7LGO58?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.37",
            "price": "$34",
            "capacity": "4.7 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K6QJHKC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.42",
            "price": "$73",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09FH79Q1K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.50",
            "price": "$73",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DXLSMBC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.52",
            "price": "$146",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09FH6X7R7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.88",
            "price": "$15",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000AAJSYY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.88",
            "price": "$15",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0099RK4BA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$36.90",
            "price": "$44",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0100ZPPQS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.00",
            "price": "$18",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008RZLSWE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.00",
            "price": "$185",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00LO3KR0U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.06",
            "price": "$593",
            "capacity": "4 TB x4",
            "warranty": "No warranty",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083BML3LY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.34",
            "price": "$316",
            "capacity": "4.7 GB x1800",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00WVGU0A6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.48",
            "price": "$112",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009L5I5PC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.49",
            "price": "$30",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000RA6TKI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.49",
            "price": "$150",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H2FPFW5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.50",
            "price": "$600",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XV6ZJNW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.50",
            "price": "$15",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000E9X5DQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.72",
            "price": "$34",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007IPDTE4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.73",
            "price": "$830",
            "capacity": "22 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CF7VF7HM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.79",
            "price": "$38",
            "capacity": "25 GB x40",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079P9B5RQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.95",
            "price": "$47",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J88MISC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$37.98",
            "price": "$19",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IMS8MI4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.00",
            "price": "$38",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00B6TMDJA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.00",
            "price": "$19",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00C9TECFO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.00",
            "price": "$19",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005PWVEJ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.02",
            "price": "$76",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0082D57ZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.15",
            "price": "$15",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VSJQ1PU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.16",
            "price": "$95",
            "capacity": "25 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079PT76TP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.36",
            "price": "$48",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004477BQQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.50",
            "price": "$77",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VSPL8FJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.50",
            "price": "$77",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B094QZLJQ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.83",
            "price": "$466",
            "capacity": "12 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B088PBV65K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$38.99",
            "price": "$39",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0150P3JIE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.00",
            "price": "$39",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0088PUEPK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.12",
            "price": "$78",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09TTSM8HB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.43",
            "price": "$631",
            "capacity": "8 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073ZMWD98?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.44",
            "price": "$237",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09FHB8HVB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.44",
            "price": "$71",
            "capacity": "1.8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B075NZSVLL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.55",
            "price": "$158",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CVN4W5TB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.59",
            "price": "$30",
            "capacity": "25 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079PB3ZG1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.59",
            "price": "$49",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079PTTJXC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.60",
            "price": "$20",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007YJIGPQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.78",
            "price": "$20",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007ORWZFU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.81",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B086W5JQT5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.90",
            "price": "$80",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079BQS5WQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.96",
            "price": "$50",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008HV6MSI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.96",
            "price": "$10",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079P834B6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RT81SH4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0797FYTHZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RT823D9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09LQHMWBX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X86DN9M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X8LNVSY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B2CYCNMG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$39.99",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07N2PL3LQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.00",
            "price": "$80",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005PVXH1S?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.00",
            "price": "$80",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07M9VCHDG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.00",
            "price": "$240",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01K4241D0?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.00",
            "price": "$40",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00512JC5G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.00",
            "price": "$80",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08JKGG97R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.00",
            "price": "$10",
            "capacity": "250 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00EGSGDXK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.50",
            "price": "$81",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08JKHPV1V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.56",
            "price": "$649",
            "capacity": "16 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085G222YK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.62",
            "price": "$650",
            "capacity": "8 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHBWR3SV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.75",
            "price": "$82",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D99S8Z7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.75",
            "price": "$82",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07HCMMFK3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.83",
            "price": "$49",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LXGGRWA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.95",
            "price": "$164",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W5XGYKP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.99",
            "price": "$41",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07K1QQGRH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$40.99",
            "price": "$82",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008YN6QMQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.15",
            "price": "$82",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004VFJ9MK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.38",
            "price": "$21",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079PHQXXP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.39",
            "price": "$41",
            "capacity": "25 GB x40",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079P9VQGG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.67",
            "price": "$38",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00C9TEPHE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.80",
            "price": "$105",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C2VZTBTF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.86",
            "price": "$3,349",
            "capacity": "10 TB x8",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01I0FCV8U?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.87",
            "price": "$670",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GPHB4HK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.88",
            "price": "$42",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09MK3Y7VZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.88",
            "price": "$42",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09Z5VSL2Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.90",
            "price": "$21",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00II56U0I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.96",
            "price": "$21",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00C9TECFO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.99",
            "price": "$42",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SP88KXX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$41.99",
            "price": "$42",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09ZQ5BSS1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.00",
            "price": "$84",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BN4B4L54?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.04",
            "price": "$42",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "Hybrid",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LWRTRZU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.22",
            "price": "$38",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CGC1VBC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.45",
            "price": "$20",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00552PN32?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.48",
            "price": "$17",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0023R4LXA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.50",
            "price": "$85",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GKK35BT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.50",
            "price": "$85",
            "capacity": "2 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07WGKCDNH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.51",
            "price": "$10",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000A0CV9S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.51",
            "price": "$10",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0007PGADE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.53",
            "price": "$20",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KALCGUG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.72",
            "price": "$43",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B001U3QBDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.86",
            "price": "$1,200",
            "capacity": "14 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Y3PMCGY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$42.90",
            "price": "$343",
            "capacity": "8 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B082P7R5X9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.16",
            "price": "$11",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079PB776M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.18",
            "price": "$27",
            "capacity": "25 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097PBC21W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.19",
            "price": "$32",
            "capacity": "25 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079P9TWLZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.43",
            "price": "$174",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00FW3RM9M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.49",
            "price": "$43",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XLVDX3P?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.50",
            "price": "$87",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07M9VCHDG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.57",
            "price": "$697",
            "capacity": "8 TB x2",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07QF6HY5V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.73",
            "price": "$44",
            "capacity": "1 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HGR3SQN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XS1MF45?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XS1L56Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XS1FB6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XS1FB5S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRZBL13?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRZ1MLH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRYTN1X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRX5RB4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRZ616H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.74",
            "price": "$35",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRZ1V1P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.75",
            "price": "$1,050",
            "capacity": "12 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ8XWWWT?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.75",
            "price": "$700",
            "capacity": "8 TB x2",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CH219TF2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$43.75",
            "price": "$175",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01CG0DCJM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.25",
            "price": "$177",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07W4QTBSF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.40",
            "price": "$20",
            "capacity": "450 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002P4MLOC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.42",
            "price": "$40",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01IF5M4EQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.57",
            "price": "$21",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C3Q7J5FF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.82",
            "price": "$90",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B097TSN41S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.85",
            "price": "$112",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C2DX8969?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.92",
            "price": "$54",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XL5CSPZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.97",
            "price": "$14",
            "capacity": "320 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0150O7MJM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.98",
            "price": "$22",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079P9LTL5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$44.99",
            "price": "$45",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LNJBA2I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$45.00",
            "price": "$90",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TTSM8HB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$45.00",
            "price": "$18",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002XZC4B8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$45.00",
            "price": "$90",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B087DFFJRD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$45.31",
            "price": "$181",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0090UGQ2C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$45.70",
            "price": "$21",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00DVPJ7ZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$45.78",
            "price": "$366",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074LK3XHJ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.06",
            "price": "$92",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08F1VVBL9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.24",
            "price": "$37",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00MHT2NCA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.25",
            "price": "$185",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DTR8RGR?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.38",
            "price": "$29",
            "capacity": "25 GB x25",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J5UT17Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.50",
            "price": "$93",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IRV005E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.65",
            "price": "$28",
            "capacity": "600 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009T0FEYE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.67",
            "price": "$56",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XL5CSPZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.70",
            "price": "$22",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002UPDXLQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.87",
            "price": "$750",
            "capacity": "16 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHC7FMKK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.87",
            "price": "$375",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07S4N6ZCR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$46.94",
            "price": "$751",
            "capacity": "8 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09BD6W4BY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.00",
            "price": "$47",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07NGTZWWZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.12",
            "price": "$24",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0043SEMMW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.30",
            "price": "$118",
            "capacity": "2.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-6",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C68F5T6D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.50",
            "price": "$190",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CM8W1S8K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.51",
            "price": "$38",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JPC4X6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.85",
            "price": "$48",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B082LWP1MQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.96",
            "price": "$12",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074Z3XH7J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$47.99",
            "price": "$48",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XLVDX3P?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.00",
            "price": "$48",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00724D7AM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.04",
            "price": "$192",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TP5J9FS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.17",
            "price": "$289",
            "capacity": "6 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00WOSJLQA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.30",
            "price": "$11",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CQFV6J5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.33",
            "price": "$580",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H28SY36?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.33",
            "price": "$29",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B009OZ32OS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.62",
            "price": "$194",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00FJRS5BA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.83",
            "price": "$23",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00LH2548I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.83",
            "price": "$23",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003MOJHGE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.83",
            "price": "$23",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09VKKTDJ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$48.92",
            "price": "$23",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001T429TQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.00",
            "price": "$980",
            "capacity": "2 TB x10",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHJSKKV2?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.02",
            "price": "$25",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06Y5SH8LV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.17",
            "price": "$59",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LXGGRWA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.32",
            "price": "$99",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "Hybrid",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01M1NHCZT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.45",
            "price": "$19",
            "capacity": "128 GB x3",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CCV9S84T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.49",
            "price": "$1,188",
            "capacity": "12 TB x2",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07W5V8GC9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.50",
            "price": "$99",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08M97SLRP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.50",
            "price": "$99",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00FJRS628?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.90",
            "price": "$25",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073SBZ8YH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.94",
            "price": "$799",
            "capacity": "4 TB x4",
            "warranty": "No warranty",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083BML3LY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.95",
            "price": "$50",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008DJE7HW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.95",
            "price": "$30",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00PU1ZW7U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.98",
            "price": "$20",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C39MX32X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.98",
            "price": "$23",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001T40DGM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.98",
            "price": "$25",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B079K28QHF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$49.99",
            "price": "$250",
            "capacity": "5 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TKFEEBW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.00",
            "price": "$100",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Q37V1C9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.00",
            "price": "$100",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D99S8Z7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.00",
            "price": "$125",
            "capacity": "100 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C3SNB2FV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.00",
            "price": "$25",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0832V3FPB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.00",
            "price": "$50",
            "capacity": "1 TB",
            "warranty": "3 months",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07WHJ5D4J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.00",
            "price": "$50",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005WU1OTO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.79",
            "price": "$41",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00OGP55TQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.96",
            "price": "$24",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003O7DKMQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$50.96",
            "price": "$24",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6B5TFWY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.04",
            "price": "$24",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7QMLRR4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.04",
            "price": "$24",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001T40FWY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.06",
            "price": "$24",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0007SL4IW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.50",
            "price": "$103",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WMSVHD4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.50",
            "price": "$206",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B089C5P5SX?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.55",
            "price": "$3,299",
            "capacity": "8 TB x8",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084SR6SXN?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.55",
            "price": "$825",
            "capacity": "8 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01CENX44E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$51.90",
            "price": "$32",
            "capacity": "25 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004K4VNYO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.00",
            "price": "$1",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00465WA3A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.44",
            "price": "$105",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07ZQ97H3W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.50",
            "price": "$210",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BVZHZ2W6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.50",
            "price": "$210",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9LWC5VV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.50",
            "price": "$210",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C1TJFZF6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.52",
            "price": "$105",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YFFJK2Q?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.53",
            "price": "$53",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0744NWZTM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.70",
            "price": "$27",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CXJM2K1F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$52.72",
            "price": "$33",
            "capacity": "25 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004K4ZIJA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.00",
            "price": "$106",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP9BMJRW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.00",
            "price": "$106",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CDM2HSS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.08",
            "price": "$25",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BLXZN74X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.12",
            "price": "$850",
            "capacity": "8 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ8WTYGN?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.12",
            "price": "$8",
            "capacity": "160 GB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000RT77I2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.17",
            "price": "$25",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003MUT66Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.17",
            "price": "$25",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YXH77KV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.17",
            "price": "$25",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00O0IDJT2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.50",
            "price": "$107",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJVPS4SB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.50",
            "price": "$21",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005FE69CE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.62",
            "price": "$429",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B089C3TZL9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.99",
            "price": "$54",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YBYZQ1V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$53.99",
            "price": "$108",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CM8M8293?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.00",
            "price": "$108",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08G838VTV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.00",
            "price": "$135",
            "capacity": "25 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K7L2DFW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.17",
            "price": "$65",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XRJSTQX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.24",
            "price": "$108",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B098T4JG95?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.50",
            "price": "$109",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWFMXMJR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.50",
            "price": "$109",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C85X6R2M?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.73",
            "price": "$68",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00QUFWMLE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.83",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C14TF467?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.94",
            "price": "$879",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XBNCY12?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.99",
            "price": "$55",
            "capacity": "100 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BKTQWX91?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$54.99",
            "price": "$55",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01J9B954C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9CW4D43?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07F2DZK2Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GVDNTGJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08Z3P65PX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07ZQ97H3W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CG7SM5K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.00",
            "price": "$220",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJ2N9485?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.20",
            "price": "$110",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C5JNWF58?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.21",
            "price": "$26",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001RQPAHO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.61",
            "price": "$890",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082JNFWD9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.66",
            "price": "$111",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CFLSHNL3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.66",
            "price": "$13",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00081A2KY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.75",
            "price": "$56",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D998212?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.98",
            "price": "$112",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08HN37XC1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$55.99",
            "price": "$56",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7JSMX2H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.00",
            "price": "$112",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07PGWXQCM?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.00",
            "price": "$112",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08LB9TMYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.05",
            "price": "$1,121",
            "capacity": "2 TB x10",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFGF79S6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.07",
            "price": "$56",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09ZYQ84CM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.25",
            "price": "$900",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ91NG66?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.25",
            "price": "$900",
            "capacity": "8 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN6F5J67?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.25",
            "price": "$900",
            "capacity": "4 TB x4",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00M77UITC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.50",
            "price": "$113",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWXK95PY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.50",
            "price": "$113",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089Y3262K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.50",
            "price": "$113",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWFN1N87?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.50",
            "price": "$113",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HCMMFK3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.67",
            "price": "$85",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VXG7KXU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.72",
            "price": "$113",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CN3MNWM4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.75",
            "price": "$227",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B25M8FXX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.92",
            "price": "$114",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09DVRBNWV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.93",
            "price": "$57",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001U3QBDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.99",
            "price": "$57",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WMP5B5N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.99",
            "price": "$57",
            "capacity": "1 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07FF8QYRR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$56.99",
            "price": "$57",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QRR7DKQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.20",
            "price": "$43",
            "capacity": "750 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000XPPXFO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.23",
            "price": "$27",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CHCGRH4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.29",
            "price": "$2,750",
            "capacity": "6 TB x8",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084S3TYYM?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.34",
            "price": "$27",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001RR60V8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.41",
            "price": "$57",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07B4G19X3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.45",
            "price": "$27",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GQG9PXQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.45",
            "price": "$27",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00L1FQ31I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.50",
            "price": "$115",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CM941D89?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.60",
            "price": "$230",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BZ62Y2S6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.95",
            "price": "$232",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BZMLXNLS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.98",
            "price": "$29",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000O78HRC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.99",
            "price": "$58",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07PHJCCY3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$57.99",
            "price": "$58",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CKFDPJ3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.00",
            "price": "$232",
            "capacity": "2 TB x2",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFGBZ5CB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.10",
            "price": "$581",
            "capacity": "1 TB x10",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFGFJX7D?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.16",
            "price": "$116",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GVDNTGJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.22",
            "price": "$30",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083ZS4HYD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.24",
            "price": "$87",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C3BGFX9R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.36",
            "price": "$233",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08RX3TWJZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.48",
            "price": "$234",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08RX4QKXS?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.50",
            "price": "$117",
            "capacity": "1 TB x2",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFGC2CPP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.50",
            "price": "$585",
            "capacity": "1 TB x10",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHJPRLBT?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.55",
            "price": "$117",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFJWCWXH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.56",
            "price": "$15",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08H2BSZPT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.58",
            "price": "$37",
            "capacity": "128 GB x5",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08L4TQJXB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.85",
            "price": "$30",
            "capacity": "512 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09B1HMJ9Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$58.99",
            "price": "$59",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BKKSPM9R?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.01",
            "price": "$118",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C5SC2V95?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.25",
            "price": "$59",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07ZPRK68X?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.37",
            "price": "$950",
            "capacity": "4 TB x4",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D7ZY91S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.43",
            "price": "$59",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B089C73T72?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.47",
            "price": "$28",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00DVPJ7ZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.49",
            "price": "$119",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097TSN41S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.57",
            "price": "$28",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MG8SC9S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.68",
            "price": "$119",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QV5KJHV?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.90",
            "price": "$240",
            "capacity": "4 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01HD6ZLIY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.90",
            "price": "$30",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005X3KOZA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.98",
            "price": "$120",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B996CJ64?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GJBTCF6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GJCS69D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0869688QV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BX6356LN?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP9CXCXG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07B4G19X3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07ZGJVTZK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08NCDG9X9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$59.99",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BRQ2N7JP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$120",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YD5F561?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$120",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07TY2TN64?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$120",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LB9TMYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$120",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5JNWF58?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$120",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJ4JCMLG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$240",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ62Y2S6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.00",
            "price": "$30",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003SALVN4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.19",
            "price": "$60",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BBWH1R8H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.21",
            "price": "$72",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01IF55U1A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.26",
            "price": "$241",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BHZQGN26?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.38",
            "price": "$30",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079K28QHF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.64",
            "price": "$28",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000B02B92?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.71",
            "price": "$61",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C8XMH264?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.80",
            "price": "$61",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BBWH1R8H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.83",
            "price": "$18",
            "capacity": "300 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0035G0J98?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.84",
            "price": "$91",
            "capacity": "1.5 TB",
            "warranty": "2 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XDKWLJH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$60.92",
            "price": "$39",
            "capacity": "64 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09D3QK71Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.15",
            "price": "$26",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074Z4P35M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.17",
            "price": "$61",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09NQJMW28?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.50",
            "price": "$31",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07997QV4Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.58",
            "price": "$123",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B089C6LZ42?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.60",
            "price": "$29",
            "capacity": "4.7 GB x100",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001SHZJWS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.75",
            "price": "$247",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C91RNCDV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.89",
            "price": "$62",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078TJ17QF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.96",
            "price": "$15",
            "capacity": "50 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VV293SH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$61.99",
            "price": "$62",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082BX6999?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.00",
            "price": "$62",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CRZ2PRM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.09",
            "price": "$497",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07S4N6ZCR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.10",
            "price": "$31",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005PWVEJ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.37",
            "price": "$249",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C91RNCDV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.40",
            "price": "$62",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "Hybrid",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LWRTRZU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.46",
            "price": "$250",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C5SF333L?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.47",
            "price": "$20",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08L4Y37PC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.47",
            "price": "$20",
            "capacity": "320 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0150O7MJM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.47",
            "price": "$40",
            "capacity": "64 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09YCXYVCL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$125",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YFFJK2Q?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$125",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C3B4BFQ3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$500",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01FT19136?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$320",
            "capacity": "512 GB x10",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHJPX6VD?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$125",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "Hybrid",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M1NHCZT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$38",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002P4J3YI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.50",
            "price": "$125",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00QJA931C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.55",
            "price": "$63",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09DVQQL9G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.60",
            "price": "$250",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C59FBGG1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.61",
            "price": "$63",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07Y5VDNT9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.70",
            "price": "$125",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C59G53GS?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.70",
            "price": "$29",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B016PIV35G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.84",
            "price": "$63",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00F7R07UE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$62.99",
            "price": "$63",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C66DDJSW?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.04",
            "price": "$30",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000COZ45K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.30",
            "price": "$95",
            "capacity": "1.5 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-5",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003HLNURO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.30",
            "price": "$19",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B008P94WVG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.35",
            "price": "$63",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C65CMZK7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.50",
            "price": "$127",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZK6NBQM?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.53",
            "price": "$27",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RG16VP1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.62",
            "price": "$15",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09MJSZWXS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.63",
            "price": "$16",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SYRW97F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.71",
            "price": "$255",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08RX3343D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.76",
            "price": "$16",
            "capacity": "250 GB",
            "warranty": "3 months",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CCLQ3165?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.79",
            "price": "$16",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B081DF9LK8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.83",
            "price": "$15",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00081A2KY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.90",
            "price": "$128",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJ7XKD15?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.93",
            "price": "$64",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08T8LL7G8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$63.98",
            "price": "$64",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XDJ673F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.00",
            "price": "$128",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C58FVB9V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.00",
            "price": "$64",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08Y1T2GK7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.02",
            "price": "$128",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08QB93S6R?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.12",
            "price": "$64",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B099FNMV34?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.12",
            "price": "$26",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CTLKD5XF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.17",
            "price": "$77",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XR6KDQ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.28",
            "price": "$771",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000HWVLX6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.34",
            "price": "$64",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09JQMGHDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.40",
            "price": "$33",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJZLF2FW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.43",
            "price": "$33",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083ZS4HYD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.43",
            "price": "$33",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CK7T9FG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.74",
            "price": "$129",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C91X5DZL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.79",
            "price": "$81",
            "capacity": "25 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K7LDZVI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.88",
            "price": "$65",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07BN217QG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.89",
            "price": "$65",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RT9LLFM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.98",
            "price": "$130",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QV5KJHV?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.99",
            "price": "$65",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Y5VDNT9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.99",
            "price": "$65",
            "capacity": "100 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CRR7CN5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.99",
            "price": "$65",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TRFF6FG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$64.99",
            "price": "$65",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QLRJ89Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$130",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09F5W62N8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$130",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078HTR1FM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$130",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07T3KD58D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$130",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09PCWWD1B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$130",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN3MNWM4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$260",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09FRRWVWX?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$260",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZMLXNLS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.00",
            "price": "$39",
            "capacity": "600 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00E7LGO58?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.23",
            "price": "$17",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0897BBCYP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.41",
            "price": "$33",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BS9W92M1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.55",
            "price": "$14",
            "capacity": "8.5 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BJ59HXBD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.59",
            "price": "$525",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CMDGGJTZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.62",
            "price": "$63",
            "capacity": "960 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08YYTNSP7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.77",
            "price": "$20",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06ZZF166X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.94",
            "price": "$31",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00FMOYMT4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.94",
            "price": "$66",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00CRZ2PRM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$65.98",
            "price": "$17",
            "capacity": "256 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09B1FP5X1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.04",
            "price": "$31",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008BWMVTM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.06",
            "price": "$17",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08SWR78YP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.10",
            "price": "$132",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TY2TN64?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.25",
            "price": "$265",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BV92XSMG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.25",
            "price": "$530",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CMDGGJTZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.37",
            "price": "$17",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C26NP37B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.37",
            "price": "$17",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C26NP37B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.39",
            "price": "$34",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H21182F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.60",
            "price": "$31",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005B2NKSG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.62",
            "price": "$33",
            "capacity": "500 GB",
            "warranty": "1 month",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BCP3PYL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.63",
            "price": "$20",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003AQJUS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.67",
            "price": "$40",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B016YKFAKE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.82",
            "price": "$267",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C48HN4Z9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$66.84",
            "price": "$134",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CSG5DXQ9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.06",
            "price": "$84",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004OMGOQY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.25",
            "price": "$269",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CGKY9K2Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.39",
            "price": "$539",
            "capacity": "4 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07PRY2TPT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.46",
            "price": "$17",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08RG38YWT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.50",
            "price": "$135",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08P2CG4JK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.74",
            "price": "$85",
            "capacity": "50 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009X6HG3G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.75",
            "price": "$271",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWKRLJMP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.84",
            "price": "$136",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08RK2SR23?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.98",
            "price": "$34",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082VZC5JN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.99",
            "price": "$68",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B088N3QDS9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.99",
            "price": "$68",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09DVQQL9G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$67.99",
            "price": "$68",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B086W5JQT5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.04",
            "price": "$16",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000A0CV9S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.08",
            "price": "$32",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000KKKDAC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.21",
            "price": "$29",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZVPPNS9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.24",
            "price": "$273",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CHHFR1LG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.34",
            "price": "$35",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TQSYBPH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.36",
            "price": "$350",
            "capacity": "512 GB x10",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFGFKPLT?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.36",
            "price": "$175",
            "capacity": "256 GB x10",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BS8775CD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.44",
            "price": "$68",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08QBJ2YMG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.50",
            "price": "$137",
            "capacity": "400 GB x5",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XRZNLY7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.75",
            "price": "$220",
            "capacity": "128 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BRPMQ8TB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.75",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B25LZGGW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.99",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09JQMGHDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.99",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWFQGH31?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$68.99",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B9Y3DB2K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.00",
            "price": "$138",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C14TF467?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.01",
            "price": "$29",
            "capacity": "8.5 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000UWX70S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.14",
            "price": "$35",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08L87V4PH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.15",
            "price": "$32",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002P0RVAK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.25",
            "price": "$138",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7CMZ3QH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.29",
            "price": "$59",
            "capacity": "8.5 GB x100",
            "warranty": "1 month",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CB987ZW7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.39",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082LWP1MQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.40",
            "price": "$33",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009U5KOYS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.40",
            "price": "$139",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09FH79Q1K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.46",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHG2JPR8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.49",
            "price": "$69",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08T8LL7G8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.50",
            "price": "$139",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CK2TC9XQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.56",
            "price": "$104",
            "capacity": "1.5 TB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJMRW771?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.57",
            "price": "$139",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09Q2NCFS7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.63",
            "price": "$209",
            "capacity": "3 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TKFEEJ4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.67",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08V83JZH4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.88",
            "price": "$35",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XZLN9KM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.98",
            "price": "$35",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0786QNS9B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.98",
            "price": "$35",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B007YJIGPQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.99",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YD579WM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.99",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CMBL4VR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.99",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C8XMH264?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$69.99",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPSZY7VW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$140",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09BW7CZV6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$140",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B082YGVGQL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$140",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C92C9WYY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$140",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN1NFYZ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$280",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08RX3TWJZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$140",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08X4XL71S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.00",
            "price": "$84",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XRJSTQX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.20",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C28HLKNB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.23",
            "price": "$9",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077BGMX8C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.23",
            "price": "$9",
            "capacity": "64 GB x2",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Q3Y5J5T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.27",
            "price": "$18",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SYRW97F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.27",
            "price": "$18",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0897BBCYP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.27",
            "price": "$18",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08RG38YWT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.29",
            "price": "$36",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CKRX3WDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.38",
            "price": "$33",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AQNXAHQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.44",
            "price": "$70",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09NQJMW28?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.47",
            "price": "$30",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00355IJU0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.54",
            "price": "$141",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B093DNV47J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.65",
            "price": "$71",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GTYFC37?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.72",
            "price": "$71",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C5SDQVNL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.75",
            "price": "$283",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08RX4QKXS?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.99",
            "price": "$71",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B078DPCY3T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.99",
            "price": "$71",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ8HGV8N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$70.99",
            "price": "$71",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CSFR4P3J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.00",
            "price": "$71",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YFG3R5N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.25",
            "price": "$285",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9WJQ9GP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.30",
            "price": "$143",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7CKZGN6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.32",
            "price": "$143",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073SBRHH6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.33",
            "price": "$11",
            "capacity": "50 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VTNJKX1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.48",
            "price": "$18",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09XZ6BCYG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.50",
            "price": "$143",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BNQ2X2WY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.50",
            "price": "$43",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B016YJYASI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.50",
            "price": "$43",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B086Q2RGPG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.52",
            "price": "$143",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09DVRBNWV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.61",
            "price": "$110",
            "capacity": "512 GB x3",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09KG6VCNB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.67",
            "price": "$172",
            "capacity": "240 GB x10",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B094XR33WF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.68",
            "price": "$143",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0921RKRDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.80",
            "price": "$36",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00QFXOL5G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.84",
            "price": "$23",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GLFK3VH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.84",
            "price": "$23",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CD26GP52?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$71.98",
            "price": "$36",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VTS5P9B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.00",
            "price": "$144",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWFM9128?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.13",
            "price": "$17",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QG2S3YV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.22",
            "price": "$144",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09HJWCRCB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.25",
            "price": "$37",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082BJ4679?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.25",
            "price": "$37",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BX61DW5Y?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.26",
            "price": "$74",
            "capacity": "512 GB x2",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFGDG53D?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.44",
            "price": "$1,159",
            "capacity": "8 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084DD16FT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.54",
            "price": "$19",
            "capacity": "128 GB x2",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09DBTWWSF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.65",
            "price": "$73",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QV692XY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.72",
            "price": "$145",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CFLSHNL3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.92",
            "price": "$35",
            "capacity": "480 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01N0TQPQB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.94",
            "price": "$17",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0007NY0VK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$72.99",
            "price": "$73",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DDJZP3N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.00",
            "price": "$146",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C69R19Y3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.05",
            "price": "$9",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7NS71G2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.06",
            "price": "$292",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08QBL36GF?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.09",
            "price": "$585",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089C3TZL9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.26",
            "price": "$73",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7CPSN2K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.33",
            "price": "$110",
            "capacity": "1.5 TB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJMRW771?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.57",
            "price": "$294",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CMVZY43Q?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.59",
            "price": "$74",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B097TTZD48?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.59",
            "price": "$74",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CKXZBFNY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.60",
            "price": "$294",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CHFSZX9W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.76",
            "price": "$295",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7CQ2CHH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$73.90",
            "price": "$7",
            "capacity": "50 GB x2",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VTQC687?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.00",
            "price": "$148",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B099RHVB42?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.18",
            "price": "$95",
            "capacity": "128 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CDKJDLFF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.20",
            "price": "$38",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GJDHKJG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.20",
            "price": "$38",
            "capacity": "512 GB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BR7QMQHV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.20",
            "price": "$38",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP9BZLZ5?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.20",
            "price": "$38",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GBKQ9ST?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.20",
            "price": "$38",
            "capacity": "512 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09B1HMJ9Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.28",
            "price": "$74",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08XKMYCBB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.50",
            "price": "$149",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MY4KWFP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.52",
            "price": "$298",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CHGT1KFJ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.75",
            "price": "$299",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089C5P5SX?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.79",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RMYGZ1L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.83",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0150P3JIE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.85",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D998212?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.85",
            "price": "$135",
            "capacity": "1.8 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07ZDKK9TN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.88",
            "price": "$599",
            "capacity": "8 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08HGH9HK6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.88",
            "price": "$599",
            "capacity": "4 TB x2",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CYQHJV2B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.93",
            "price": "$599",
            "capacity": "4 TB x2",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07PRY2TPT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.98",
            "price": "$45",
            "capacity": "600 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07F7Q3RXB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$300",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CT3T3XZ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WDS22WL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DL4899L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B099FNMV34?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C65CMZK7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CGW1FQV4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74.99",
            "price": "$600",
            "capacity": "4 TB x2",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ8WPXS6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$150",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0899GHX8R?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$150",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9WHSZZN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$150",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CBQSJSN9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$300",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B3RNFZBK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$300",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C59FBGG1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$300",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5SF333L?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$900",
            "capacity": "12 TB",
            "warranty": "5 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B098SMDXTD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.00",
            "price": "$75",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GCL6BR4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.27",
            "price": "$32",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08HHQRH7K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.66",
            "price": "$19",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07D7Q41PM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$75.98",
            "price": "$38",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XZLN9KM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.00",
            "price": "$76",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00DUGAMIG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.03",
            "price": "$76",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0842P5GG5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.15",
            "price": "$39",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XJ2P1LK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.17",
            "price": "$195",
            "capacity": "256 GB x10",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHJRF47H?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.23",
            "price": "$76",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GYPZ8GN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.50",
            "price": "$153",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7CMZ3QH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.58",
            "price": "$153",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082VVV926?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.76",
            "price": "$19",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002FB7KT6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.78",
            "price": "$48",
            "capacity": "25 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0043VT4YK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.89",
            "price": "$18",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0007PGADE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$76.99",
            "price": "$77",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BGL4SHY8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.46",
            "price": "$40",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CKRX3WDH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.50",
            "price": "$310",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7CQ2CHH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.50",
            "price": "$310",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHGT1KFJ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.50",
            "price": "$310",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHZQGN26?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.62",
            "price": "$310",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B3RMGKBS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.80",
            "price": "$389",
            "capacity": "5 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07K6MV64X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.87",
            "price": "$58",
            "capacity": "750 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000XPPXFO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.97",
            "price": "$10",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B015CH1PJU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.97",
            "price": "$156",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BHJJ9Y77?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$77.98",
            "price": "$156",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08HN37XC1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.00",
            "price": "$156",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RTDDZHG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.00",
            "price": "$39",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09ZYQG5C1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.05",
            "price": "$10",
            "capacity": "128 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09Y98MGLY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.05",
            "price": "$10",
            "capacity": "64 GB x2",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09K7FBSHM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.09",
            "price": "$20",
            "capacity": "128 GB x2",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09DBTWWSF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.09",
            "price": "$20",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XZ6BCYG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.09",
            "price": "$20",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B081DF9LK8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.09",
            "price": "$25",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C2D95SZZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.48",
            "price": "$20",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YYJL21Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.48",
            "price": "$20",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XC1WGQR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.71",
            "price": "$79",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YFGSDQR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.75",
            "price": "$315",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BVQMH381?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.75",
            "price": "$38",
            "capacity": "480 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N0TQPQB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.78",
            "price": "$158",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BV1GXT31?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.83",
            "price": "$10",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073JYC4XM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.99",
            "price": "$79",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09D8TCGG6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$78.99",
            "price": "$79",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZYNHPW2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.00",
            "price": "$79",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YD579WM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.05",
            "price": "$79",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08DKB5LWY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.17",
            "price": "$3,800",
            "capacity": "6 TB x8",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LZ8I941?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.50",
            "price": "$159",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082YGVGQL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.62",
            "price": "$17",
            "capacity": "8.5 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJ59HXBD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.80",
            "price": "$20",
            "capacity": "250 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00MBN73F4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.80",
            "price": "$4",
            "capacity": "50 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VTR51P7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.85",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B016YJQQZ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.90",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZYQ84CM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.94",
            "price": "$320",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08RX3343D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.95",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0792LL46V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.96",
            "price": "$20",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002FB7KT6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.97",
            "price": "$26",
            "capacity": "320 GB",
            "warranty": "3 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SG3TZ2M?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.98",
            "price": "$160",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C47ZX1WB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.98",
            "price": "$40",
            "capacity": "100 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CR58MNT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.98",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B016YJQQZ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.98",
            "price": "$40",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LXMAZR3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.98",
            "price": "$41",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082WNRRLY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LXPENRR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9J48X93?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093275Z7V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097TTZD48?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09F5P2JT8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09Q8CBSCH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01J9B954C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CK39YR9V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$79.99",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CKXZBFNY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$160",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MFZXR1B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$160",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08F1VVBL9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$160",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09Q2NCFS7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$160",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BGJ1JF8L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$160",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CSG5DXQ9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$240",
            "capacity": "3 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0050SI9W8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$320",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHHFR1LG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.00",
            "price": "$320",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHFSZX9W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.10",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B86JFXR2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.23",
            "price": "$21",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08589RK3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.31",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CRCC9863?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.33",
            "price": "$41",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08DG7FB61?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.47",
            "price": "$227",
            "capacity": "4.7 GB x600",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NPBQHP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.48",
            "price": "$322",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09H1M6ZRT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$80.50",
            "price": "$80",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08X4Z4R1Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.00",
            "price": "$486",
            "capacity": "6 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071CWRJ7D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.22",
            "price": "$26",
            "capacity": "64 GB x5",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BXHJ3873?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.47",
            "price": "$81",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01AC27FMG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.67",
            "price": "$49",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LWYTHQU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.68",
            "price": "$20",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0047V24MA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.91",
            "price": "$82",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7CKVCCV?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.94",
            "price": "$82",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SKG68CB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.98",
            "price": "$164",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BHJDY57J?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$81.99",
            "price": "$21",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YBYTV15?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.01",
            "price": "$42",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CJ4BSK8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.24",
            "price": "$35",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002LZMY10?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.25",
            "price": "$329",
            "capacity": "2 TB x2",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5S6BT1X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.36",
            "price": "$329",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08QBL36GF?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.46",
            "price": "$20",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08LK8ZYJ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.49",
            "price": "$99",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078RTFSPS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.50",
            "price": "$165",
            "capacity": "2 TB",
            "warranty": "6 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9F65B21?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.50",
            "price": "$330",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09H1M6ZRT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.50",
            "price": "$99",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LXAKE6W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.81",
            "price": "$21",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08K79T9G5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.91",
            "price": "$234",
            "capacity": "4.7 GB x600",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GS66WYB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.98",
            "price": "$39",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00V9LHEBC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.99",
            "price": "$166",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08RK2SR23?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$82.99",
            "price": "$83",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QV692XY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.20",
            "price": "$32",
            "capacity": "128 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CF49PSC1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.29",
            "price": "$20",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LK8ZYJ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.32",
            "price": "$100",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09K52Q132?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.33",
            "price": "$150",
            "capacity": "1.8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00LL217F8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.50",
            "price": "$167",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QVD9V7R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.67",
            "price": "$1,004",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073ZLTQML?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.75",
            "price": "$11",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B077BGMX8C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.82",
            "price": "$168",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089C6LZ42?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.89",
            "price": "$43",
            "capacity": "512 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C1PRYPYX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.94",
            "price": "$21",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08K79T9G5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.96",
            "price": "$43",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C387XPZS?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$83.96",
            "price": "$43",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CDWH7W5T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.00",
            "price": "$84",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08V83JZH4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.45",
            "price": "$36",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00DT6OWFG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.49",
            "price": "$238",
            "capacity": "4.7 GB x600",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NW5SYD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.63",
            "price": "$650",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SG5Z6CQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.98",
            "price": "$85",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0842P5GG5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.99",
            "price": "$170",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093DNV47J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.99",
            "price": "$85",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0792LL46V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$84.99",
            "price": "$85",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C28HLKNB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$170",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHJDY57J?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$170",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJ116VV2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$170",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CVMSV5J6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$340",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B3RMGKBS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$40",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002P0RVAK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$170",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9WGS6MC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$42",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09HKG6SDF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.00",
            "price": "$51",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00PU1ZW7U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.15",
            "price": "$34",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TQ512C7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.46",
            "price": "$427",
            "capacity": "5 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074PB69Y7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.48",
            "price": "$21",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01F8Q16U8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.53",
            "price": "$86",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CP68JNT1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.86",
            "price": "$11",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TQS634Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.90",
            "price": "$22",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XC1WGQR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.90",
            "price": "$22",
            "capacity": "256 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GJB3P7H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$85.93",
            "price": "$220",
            "capacity": "256 GB x10",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFJZNHV9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$86.17",
            "price": "$243",
            "capacity": "4.7 GB x600",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VLC6RXR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$86.19",
            "price": "$86",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005WU1OTO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$86.25",
            "price": "$22",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WW54LLV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$86.29",
            "price": "$22",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YYJL21Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$86.52",
            "price": "$664",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7Y4TFJR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.00",
            "price": "$348",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C48HN4Z9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.07",
            "price": "$22",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082WNC4NK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.24",
            "price": "$1,340",
            "capacity": "15.4 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SK8GSYZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.39",
            "price": "$87",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08F27QGHX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.44",
            "price": "$1,399",
            "capacity": "8 TB x2",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CKS2ZGM3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.46",
            "price": "$21",
            "capacity": "240 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01F9G43WU?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.50",
            "price": "$350",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C349JX71?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.68",
            "price": "$44",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0786QNS9B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.87",
            "price": "$45",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CFP1YH3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.87",
            "price": "$45",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08T8LHL38?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.90",
            "price": "$88",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C59G4TLQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.99",
            "price": "$88",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWFNT2FB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$87.99",
            "price": "$88",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C4KNB2YK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$88.25",
            "price": "$88",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07P9W5HJV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$88.28",
            "price": "$41",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00Q4KWPYY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$88.32",
            "price": "$53",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LWYTHQU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$88.36",
            "price": "$11",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07HHD7C7T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$88.50",
            "price": "$27",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XQZ88HV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$88.99",
            "price": "$89",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CRCC9863?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.00",
            "price": "$178",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09F6279PY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.11",
            "price": "$356",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CBYZ6DD1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.24",
            "price": "$178",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08QB93S6R?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.26",
            "price": "$23",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08589RK3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.32",
            "price": "$21",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0000DHB2H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.36",
            "price": "$21",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000COZ504?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.41",
            "price": "$38",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005F2YPH2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.50",
            "price": "$21",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01N5IB20Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.65",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077SF8KMG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.75",
            "price": "$359",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08HBPMPZ2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.80",
            "price": "$23",
            "capacity": "256 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BX62TD7L?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.80",
            "price": "$23",
            "capacity": "128 GB x2",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XR2MTHK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.83",
            "price": "$54",
            "capacity": "600 GB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003FW9T0M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.99",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YFG3R5N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.99",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5DG6C4Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.99",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09VS3FCQ2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.99",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RMHC8L8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.99",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7CKVCCV?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$89.99",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP68JNT1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.00",
            "price": "$180",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7CKZGN6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.00",
            "price": "$180",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5SC2V95?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.00",
            "price": "$180",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C59G53GS?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.00",
            "price": "$360",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08HBPMPZ2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.00",
            "price": "$180",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B098WKQRDL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.00",
            "price": "$90",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09K3H2YHX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.15",
            "price": "$361",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09FRRWVWX?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.23",
            "price": "$46",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MSQMGLT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.23",
            "price": "$12",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDYVC5TD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.33",
            "price": "$46",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DG7FB61?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.44",
            "price": "$23",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00SX91AFY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.50",
            "price": "$695",
            "capacity": "7.7 TB",
            "warranty": "1 year",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZZSK8TN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.64",
            "price": "$43",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0089Y653A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$90.90",
            "price": "$47",
            "capacity": "512 GB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BBHBMG89?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.08",
            "price": "$219",
            "capacity": "2.4 TB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082YHNN4G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.16",
            "price": "$182",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CGF8V3XG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.17",
            "price": "$23",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07P4HBRMV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.23",
            "price": "$44",
            "capacity": "480 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01F9G46Q8?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.29",
            "price": "$23",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07P4HBRMV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.32",
            "price": "$183",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08KGXK4Z6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.33",
            "price": "$12",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B081QSJNRJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.41",
            "price": "$12",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08TJRVWV1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.44",
            "price": "$219",
            "capacity": "2.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07V5836JT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.50",
            "price": "$183",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B092RC7659?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.52",
            "price": "$23",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WB2NL8W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.56",
            "price": "$23",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C4P3MTKL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.67",
            "price": "$1,100",
            "capacity": "12 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-8",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VXRPZ88?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.67",
            "price": "$55",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XK2DKNL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.67",
            "price": "$55",
            "capacity": "600 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009T0FEYE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.76",
            "price": "$23",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082BWY2C2?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.78",
            "price": "$47",
            "capacity": "256 GB x2",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFK1YGM7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.84",
            "price": "$24",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7CRKRZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.86",
            "price": "$184",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BV1GXT31?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.98",
            "price": "$39",
            "capacity": "8.5 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000UWX70S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$91.99",
            "price": "$92",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BQG6JCRP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.00",
            "price": "$92",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B86JFXR2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.22",
            "price": "$184",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073SBRHH6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.31",
            "price": "$44",
            "capacity": "480 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01F9G46Q8?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.32",
            "price": "$709",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SG5Z6CQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.43",
            "price": "$370",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BW6715SV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.50",
            "price": "$185",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GCLLKDC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.53",
            "price": "$130",
            "capacity": "4.7 GB x300",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NSKC2N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.66",
            "price": "$24",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D7Q41PM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$92.84",
            "price": "$23",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00WT4Z32K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.00",
            "price": "$93",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DKB5LWY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.15",
            "price": "$186",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CHJXZMSG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.22",
            "price": "$56",
            "capacity": "600 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07F2RMXVH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.24",
            "price": "$93",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01I7SAHO0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.28",
            "price": "$12",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B015CH1PJU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.38",
            "price": "$93",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07822Z77M?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.60",
            "price": "$23",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0041WH1L4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.68",
            "price": "$1,439",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084SMKPPV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.70",
            "price": "$94",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0874XN4D8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.71",
            "price": "$24",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WW54LLV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.71",
            "price": "$24",
            "capacity": "256 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9QR6QHQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.79",
            "price": "$94",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089DNM8LR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.88",
            "price": "$40",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085HNP258?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.94",
            "price": "$24",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GJDJ51J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$93.98",
            "price": "$47",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWFQRJHC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.08",
            "price": "$188",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHJJ9Y77?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.25",
            "price": "$1,448",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C6BR89C2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.38",
            "price": "$94",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BPN3H914?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.50",
            "price": "$189",
            "capacity": "1 TB x2",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5S726F6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.55",
            "price": "$22",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002ZZ2CAO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.64",
            "price": "$189",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CMVXLJ8N?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.98",
            "price": "$57",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B016YJYASI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$94.99",
            "price": "$95",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08F27QGHX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.00",
            "price": "$190",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CQRBCFWD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.00",
            "price": "$190",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C348B5BZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.00",
            "price": "$57",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01MDQSSKH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.27",
            "price": "$24",
            "capacity": "256 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CJCKYND1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.36",
            "price": "$366",
            "capacity": "3.8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H1RYNS8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.57",
            "price": "$29",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0025VSUFE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.68",
            "price": "$49",
            "capacity": "512 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C1PRYPYX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.70",
            "price": "$24",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07QD6R5L7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.70",
            "price": "$49",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LTQC3CK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.73",
            "price": "$46",
            "capacity": "480 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M3POPK3?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.74",
            "price": "$135",
            "capacity": "4.7 GB x300",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VQPYPSB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.79",
            "price": "$23",
            "capacity": "240 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09KY7PMCS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.96",
            "price": "$48",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00D38VN6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$95.99",
            "price": "$96",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CTQ1BG1Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.00",
            "price": "$48",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00D38VN6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.38",
            "price": "$91",
            "capacity": "4.7 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GQHRVGJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.43",
            "price": "$1,736",
            "capacity": "18 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-9",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09H5BW9WC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.56",
            "price": "$136",
            "capacity": "4.7 GB x300",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NXM5CJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.56",
            "price": "$91",
            "capacity": "4.7 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NRC56R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.59",
            "price": "$97",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BHJGV9WR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.63",
            "price": "$29",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00BVW4A6Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.64",
            "price": "$12",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B081QSJNRJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.68",
            "price": "$25",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07QD6R5L7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$96.84",
            "price": "$48",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093HFYLNJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.01",
            "price": "$97",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C2B8CCR1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.62",
            "price": "$25",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08SWR78YP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.62",
            "price": "$50",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089DBTNRX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.64",
            "price": "$50",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BYPDLK3D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.66",
            "price": "$25",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07G3JCG8Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.67",
            "price": "$98",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MFZY2F2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$97.82",
            "price": "$391",
            "capacity": "4 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09G2P4PYP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.00",
            "price": "$49",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00436G0I8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.14",
            "price": "$98",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GTYFC37?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.33",
            "price": "$197",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CGF8V3XG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.36",
            "price": "$25",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "External 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SMFX34S?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.43",
            "price": "$197",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0D1S6DMCY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.52",
            "price": "$25",
            "capacity": "256 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09B1FP5X1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.74",
            "price": "$197",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08XKMCJTB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.98",
            "price": "$49",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08QBMD6P4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$98.99",
            "price": "$99",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B078STRHBX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.00",
            "price": "$198",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BYRRD36V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.00",
            "price": "$99",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BQG6JCRP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.01",
            "price": "$792",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BFDKPWPR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.18",
            "price": "$25",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7CRKRZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.25",
            "price": "$99",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B2DCT1MP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.32",
            "price": "$381",
            "capacity": "3.8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H1RYNS8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.50",
            "price": "$199",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B087DFFJRD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.56",
            "price": "$94",
            "capacity": "4.7 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VRLC169?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.61",
            "price": "$13",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7NTY2S6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.80",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09K3H2YHX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.91",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJGJ6R8D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.97",
            "price": "$30",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06ZZF166X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.97",
            "price": "$32",
            "capacity": "32 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DFYZ8FF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.97",
            "price": "$32",
            "capacity": "32 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MLGYB5F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.98",
            "price": "$200",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C47ZX1WB?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.98",
            "price": "$200",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08D9W2KDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.99",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TLYWMYW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.99",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TKYWB3K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.99",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GYPZ8GN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.99",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08XKMYCBB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.99",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C59G4TLQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$99.99",
            "price": "$100",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CQZCWHTQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$100",
            "price": "$200",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BYRRD36V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$100",
            "price": "$400",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BW6715SV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$100",
            "price": "$40",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002XZC4B8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$100",
            "price": "$30",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B013GUPRFS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$100",
            "price": "$120",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01IF55U1A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$100",
            "price": "$13",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B1VMJ394?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$386",
            "capacity": "3.8 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SG5Z88C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$47",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NNC6NQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$95",
            "capacity": "4.7 GB x200",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NPXYSZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$101",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BHJF2VRN?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$26",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CH8YXX1G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$52",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7DQJQL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$26",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08Z3GQGKX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$101",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01I7SAHO0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$51",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B25LQQPC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$72",
            "capacity": "4.7 GB x150",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VPL7J8H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$101",
            "price": "$13",
            "capacity": "64 GB x2",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07K7Z8FJ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$26",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GJDJ51J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$26",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WB2NL8W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$130",
            "capacity": "128 GB x10",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJF2FBCQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$26",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7DMBVF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$51",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004KIJZ54?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$13",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B013TMNPBQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$245",
            "capacity": "2.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07V5836JT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$16",
            "capacity": "32 GB x5",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YZSXG21?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$52",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07C8Y31G2?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$51",
            "capacity": "100 GB x5",
            "warranty": "10 years",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B011PIJPOC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$102",
            "price": "$52",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XVSDWYQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$103",
            "price": "$24",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NQRSKJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$103",
            "price": "$24",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00OY0SQXA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$103",
            "price": "$103",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J4USXF6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$103",
            "price": "$49",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VMHL8V3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$103",
            "price": "$1,589",
            "capacity": "15.4 TB",
            "warranty": "3 months",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CFWW61WW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$103",
            "price": "$103",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5SDQVNL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$44",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005F2YPH2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$52",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08V7GT6F3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$799",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08R3YQN2V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$799",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BPT6ZZ1X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$25",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B076Y374ZH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$200",
            "capacity": "1.9 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07GJV5KF6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$800",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09L31CTD5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$104",
            "price": "$209",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CHJXZMSG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$49",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NQR1VC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$105",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089C73T72?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$105",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9WGGZT9?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$105",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082T272LY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$12",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0001J3QAG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$25",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VQLQ3RL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$809",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08R3YQN2V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$211",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C3CWBWPC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$105",
            "price": "$105",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7CJ8H1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$106",
            "price": "$211",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BGJ1JF8L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$106",
            "price": "$53",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZYQG5C1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$106",
            "price": "$25",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074R7L9M1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$106",
            "price": "$34",
            "capacity": "32 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09LLWTMXQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$106",
            "price": "$50",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0009MZ9UK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$106",
            "price": "$25",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002U3E3D0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$48",
            "capacity": "450 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002P4MLOC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$32",
            "capacity": "300 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07FF4HB6V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$32",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008P94WVG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$64",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TUVTWY0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$107",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09ZYNHPW2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$25",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079NPSC74?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GY61ZZN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$55",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDWCC47L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$55",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BRPTSTM1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$55",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07HJB3WLB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$55",
            "capacity": "512 GB",
            "warranty": "6 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJ7BT7CT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$1,650",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CZCJ278Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$107",
            "price": "$215",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08P2CG4JK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$108",
            "price": "$27",
            "capacity": "250 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00EGSGDXK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$108",
            "price": "$28",
            "capacity": "256 GB",
            "warranty": "30 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B985Q9F1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$14",
            "capacity": "64 GB x2",
            "warranty": "2 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WG53F2Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$870",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDZWLBS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$27",
            "capacity": "250 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YFF8879?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$7",
            "capacity": "64 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B010Q588D4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$17",
            "capacity": "32 GB x5",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YZSXG21?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$28",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07G3JCG8Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$28",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7DMBVF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$219",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B4SWQ39Y?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$273",
            "capacity": "100 GB x25",
            "warranty": "10 years",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01BGW9KWY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H48412Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SQFPWY7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$28",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08LNS36PK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$109",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RPL3RKZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$109",
            "price": "$219",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0977K2C74?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$110",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0874XN4D8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$28",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WB3D5GQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$198",
            "capacity": "1.8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00LL217F8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$220",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B4SWQ39Y?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$22",
            "capacity": "100 GB x2",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CRFZR9T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$44",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-3",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009MB2IWQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$110",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C2B8CCR1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$110",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078STRHBX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$110",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7CPSN2K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$110",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B46FCJBB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$110",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BPN3H914?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$55",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B25LQQPC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$55",
            "capacity": "500 GB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00KBEK050?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$846",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08F8WX7PX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$30",
            "capacity": "272 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RM5V4TF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$210",
            "capacity": "1.9 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5Q992FP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$110",
            "price": "$55",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QVPCJN2?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$111",
            "price": "$33",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00UZFO5O2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$111",
            "price": "$889",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BW4RBGH6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$111",
            "price": "$445",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B095YPVMZ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$111",
            "price": "$57",
            "capacity": "512 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085RFNQTY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$111",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YFGG1SD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$112",
            "price": "$26",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GRYY4RQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$112",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GYKNCCP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$112",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07KXQX3S3?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$112",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08ZBTW15S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$112",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07BPK3XWW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$113",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GY61ZZN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$113",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7NS71G2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$113",
            "price": "$68",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XL67LG4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$113",
            "price": "$14",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GYKNCCP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$114",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DD76D8Z?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$114",
            "price": "$114",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08PBFP4NV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$114",
            "price": "$114",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C48B3RGL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$114",
            "price": "$114",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CMWBJVXF?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$114",
            "price": "$57",
            "capacity": "100 GB x5",
            "warranty": "10 years",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B011PIJPOC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$114",
            "price": "$229",
            "capacity": "1 TB x2",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CYQLQJG8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$220",
            "capacity": "1.9 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GJV5KF6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$1,760",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RFC7B65?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$29",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7CFXSX?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$50",
            "capacity": "8 GB x50",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LWAHRMP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$115",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BW1ZXTMD?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$115",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CYX88ZY9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$230",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09G2MZ4VR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$230",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09BJYXMDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$69",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K9S43XI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$115",
            "price": "$69",
            "capacity": "600 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07FY1TQ24?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$116",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7NTY2S6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$116",
            "price": "$28",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N5IB20Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$116",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073JYC4XM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$116",
            "price": "$30",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XD1WCQP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$116",
            "price": "$116",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7CFLDF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$28",
            "capacity": "240 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01F9G43WU?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$35",
            "capacity": "300 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07FW6ZH4C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$29",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BBWH7DBT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$117",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B087DFLF9S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$117",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08PHSVW7K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$7",
            "capacity": "64 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077VYCV37?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$899",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C96FTCS3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$899",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6YNSYT2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DD76D8Z?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08TJRVWV1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BPK3XWW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$30",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07P45BKN9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$15",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07KXQX3S3?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$30",
            "capacity": "256 GB",
            "warranty": "30 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B985Q9F1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$75",
            "capacity": "128 GB x5",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07QWTJNFS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$60",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MSQMGLT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$117",
            "price": "$60",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BDWCC47L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$118",
            "price": "$945",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BFDKPWPR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$118",
            "price": "$61",
            "capacity": "512 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BLJBQN25?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$119",
            "price": "$119",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KFS6THF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$119",
            "price": "$30",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B57T5G5L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$119",
            "price": "$25",
            "capacity": "8.5 GB x25",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000UWYM5C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$119",
            "price": "$215",
            "capacity": "1.8 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B075NZSVLL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$3,673",
            "capacity": "30.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CRJWDM9S?tag=synack-20&linkCode=ogi&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$60",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QVPCJN2?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$918",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B83T9KNB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$3",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00465WA3A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$120",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09J159SWL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$54",
            "capacity": "450 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B001GBSLJ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$12",
            "capacity": "100 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CKK142J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$60",
            "capacity": "500 GB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XFGPFZK?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$120",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHJGV9WR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$120",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YFGSDQR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$120",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C349NKQK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$72",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XK2DKNL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$14",
            "capacity": "120 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07V1KHPKK?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$120",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHJF2VRN?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$120",
            "price": "$120",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RKL6PK9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$121",
            "price": "$9",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0002J1WTC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$121",
            "price": "$31",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08ZNSNL1K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$121",
            "price": "$121",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07QZZ9YXF?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$16",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7FXHVJ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$39",
            "capacity": "32 GB x10",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0866QKPP6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$146",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06ZXQRSX8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$244",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09RTDDZHG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$980",
            "capacity": "8 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJKV2MZ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$147",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B078RTFSPS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$122",
            "price": "$8",
            "capacity": "64 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01FZPDYL6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$245",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08NJLHD6F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$942",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09DVNZY6Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$123",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09SBJJ4JW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$8",
            "capacity": "64 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007YX9OA8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$63",
            "capacity": "512 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BLJBQN25?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$945",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7Y4TFJR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$123",
            "price": "$16",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09WB2RPS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$32",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XVRYYV1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$950",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WH1X1XJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$950",
            "capacity": "7.7 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CVMVZYQT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$248",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MFZXR1B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$16",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07H9DVLBB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$249",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C3CWBWPC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$124",
            "price": "$249",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NTQGZNS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$249",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08D9W2KDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$32",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08Z3GQGKX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$250",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09BJYXMDW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$16",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08ZBTW15S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$125",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B5T6ZHGB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$64",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08LTQC3CK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$125",
            "price": "$75",
            "capacity": "600 GB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6LMV5K6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$126",
            "price": "$18",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0002IQ162?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$126",
            "price": "$63",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DK2FB7G?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$126",
            "price": "$32",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B57T5G5L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$126",
            "price": "$253",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CM42DVBR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$127",
            "price": "$38",
            "capacity": "300 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VVHJFCF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$127",
            "price": "$8",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KYK2ABI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$127",
            "price": "$510",
            "capacity": "4 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09G2P4PYP?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$15",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000CR74OQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$32",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BBWH7DBT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$15",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000CR74OQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$128",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B9C3ZVHR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$128",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C48B3RGL?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$77",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002P4J3YI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$16",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07G3H5RBT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$128",
            "price": "$257",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C27HY1QM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$129",
            "price": "$1,975",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6YVZ7XH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$129",
            "price": "$129",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B4SQB1HY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$129",
            "price": "$65",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GTXVG9P?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$39",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XJZTCQ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$130",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09G2MKHRR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$999",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN8M752B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$999",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083QKDG6D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$999",
            "capacity": "7.7 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08BVXSJBS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$999",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JQGD791?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$130",
            "price": "$19",
            "capacity": "146 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0012PUT7Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$131",
            "price": "$1,005",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BN4KNWK3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$131",
            "price": "$21",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BH8NSHQS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$131",
            "price": "$66",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08V7GT6F3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$131",
            "price": "$17",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YFGG1SD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$132",
            "price": "$28",
            "capacity": "8.5 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000V4XE0I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$132",
            "price": "$66",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B087DDSXD3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$132",
            "price": "$1,014",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082J3N7B4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$133",
            "price": "$17",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07LGKQLT5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$133",
            "price": "$34",
            "capacity": "256 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJCKYND1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$133",
            "price": "$21",
            "capacity": "160 GB",
            "warranty": "2 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000RT77I2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$133",
            "price": "$40",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00D02AHV2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$133",
            "price": "$40",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B008HDKUKW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$133",
            "price": "$40",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003DN3AB2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$134",
            "price": "$19",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000YTRUP0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$134",
            "price": "$69",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7DZM1V?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$134",
            "price": "$67",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073SBZ8YH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$134",
            "price": "$17",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XFHQGB9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$134",
            "price": "$807",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B019JJOODG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$136",
            "price": "$69",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HJB3WLB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$136",
            "price": "$815",
            "capacity": "6 TB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-7",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08ZVSLXS6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$136",
            "price": "$32",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NHF17OI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$136",
            "price": "$272",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08X4XL71S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$136",
            "price": "$68",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007U1WRN0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$35",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XD1WCQP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$35",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B086Q319JL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$70",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Q74GXK7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$70",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093BQPC1K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$1,050",
            "capacity": "7.7 TB",
            "warranty": "3 months",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0CG2LJ6J5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$35",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07XVRYYV1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$18",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H48412Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$1,099",
            "capacity": "8 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CV628X37?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$137",
            "price": "$22",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LW2NKPZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$138",
            "price": "$69",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08T1QQZ1T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$138",
            "price": "$9",
            "capacity": "64 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01FZPDYL6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$139",
            "price": "$1,065",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7NT4KZX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$139",
            "price": "$9",
            "capacity": "64 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B077VYCV37?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$139",
            "price": "$139",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B076L2L536?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$139",
            "price": "$139",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078DPCY3T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$139",
            "price": "$1,069",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CVNXVHG1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$140",
            "price": "$140",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7CJ8H1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$140",
            "price": "$140",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083GH4MQH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$140",
            "price": "$280",
            "capacity": "2 TB",
            "warranty": "3 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001UE8LRE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$140",
            "price": "$280",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CTRVZKG7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$140",
            "price": "$560",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09BJWZJ3L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$140",
            "price": "$33",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0001YH8B4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$18",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BX612GB7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$18",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0822Y6N1C?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$45",
            "capacity": "32 GB x10",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RVFZ3F3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$45",
            "capacity": "64 GB x5",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07P18ZSCM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$1,080",
            "capacity": "7.7 TB",
            "warranty": "2 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07FFGYJVR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$9",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08R59W514?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$1,080",
            "capacity": "7.7 TB",
            "warranty": "2 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJ7XFW86?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$141",
            "price": "$23",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BDTMZ8CQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$142",
            "price": "$20",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000YTRUP0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$143",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07MFZY2F2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$1,097",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7RQJDBF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$1,098",
            "capacity": "7.7 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08BVXSJBS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$1,099",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WVF8N5J?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$1,099",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JQ2F2WG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$1,099",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WWGNSH2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$143",
            "price": "$1,099",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WK8ZTS7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$144",
            "price": "$23",
            "capacity": "160 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001P80F10?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$145",
            "price": "$68",
            "capacity": "4.7 GB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001E27LPA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$145",
            "price": "$34",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004Z6OD1M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$145",
            "price": "$290",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08NJLHD6F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$145",
            "price": "$145",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08K48F6QZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$18",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000FXXU4K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$1,119",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083QKDG6D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$14",
            "capacity": "32 GB x3",
            "warranty": "2 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WZS298H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$35",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TYGQJ45?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$1,169",
            "capacity": "8 TB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WZK8YMY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$37",
            "capacity": "250 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QVP977F?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$146",
            "price": "$21",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0002IQ162?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$147",
            "price": "$44",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008HDKUKW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$19",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7FXHVJ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$37",
            "capacity": "250 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B095HX8ZYT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$9",
            "capacity": "64 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B010Q588D4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$148",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08K48F6QZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$19",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08V4Z4VRY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$19",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WB2RPS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$148",
            "price": "$2,280",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6YVCXNY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$149",
            "price": "$149",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RBCKJF4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$149",
            "price": "$149",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RL812DW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$149",
            "price": "$1,145",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C32J7QMS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$75",
            "capacity": "500 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LXMAZR3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$300",
            "capacity": "2 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00Z14UPAU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$75",
            "capacity": "500 GB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09LWYFYTW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$150",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08PHSVW7K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$300",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CM42DVBR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$600",
            "capacity": "4 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08P3Z7Q1T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$75",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KGYH1V7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$75",
            "capacity": "500 GB",
            "warranty": "1 year",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KBEK050?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$77",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7DZM1V?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$1,155",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHCMYH9Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$150",
            "price": "$1,155",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BPT6ZZ1X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$151",
            "price": "$19",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07G3H5RBT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$151",
            "price": "$10",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073K38V1P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$152",
            "price": "$39",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XG2X54Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$152",
            "price": "$2,339",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CH5PZXLQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$152",
            "price": "$39",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LNS36PK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$152",
            "price": "$24",
            "capacity": "16 GB x10",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00JES3MO0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$152",
            "price": "$39",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7CFXSX?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$153",
            "price": "$153",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B4SQB1HY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$153",
            "price": "$459",
            "capacity": "3 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071DGGJX2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$154",
            "price": "$25",
            "capacity": "32 GB x5",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07N73LB4T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$154",
            "price": "$39",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B093BRY83H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$154",
            "price": "$1,182",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CNLBQ2XV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$154",
            "price": "$20",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7DNF6G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$25",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDTMZ8CQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$39",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08QBN5J9B?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$13",
            "capacity": "8.5 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CQK5PZZ6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$1,190",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09S4YKH5S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$50",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CB3M6GT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$25",
            "capacity": "16 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DNQ8343?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$155",
            "price": "$311",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06ZXYPJ6Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$20",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B06XFHQGB9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$40",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08LNR8C1H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$80",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BXPLJJ3L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$10",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DD7J2HW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$10",
            "capacity": "32 GB x2",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B082ZGHXK8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$10",
            "capacity": "64 GB",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LFV52Y6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$10",
            "capacity": "64 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YYK13LF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$20",
            "capacity": "128 GB",
            "warranty": "30 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B984Y76P?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$25",
            "capacity": "16 GB x10",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JES3MO0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$40",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093BRY83H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$40",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WB3D5GQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$20",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01CN7WEIS?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$5",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01J5RHBQ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$1,200",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Y3ZL7X4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$156",
            "price": "$40",
            "capacity": "256 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BDT4SHC6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$157",
            "price": "$40",
            "capacity": "256 GB",
            "warranty": "15 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B084CJJRBW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$157",
            "price": "$47",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XJZTCQ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$157",
            "price": "$20",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YQ5M4V4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$158",
            "price": "$67",
            "capacity": "8.5 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00DT6OWFG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$158",
            "price": "$158",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BN217QG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$158",
            "price": "$79",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0781Z7Y3S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$158",
            "price": "$79",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B087DF38J9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$159",
            "price": "$80",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005QI4W1O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$160",
            "price": "$40",
            "capacity": "250 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YFF8879?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$160",
            "price": "$80",
            "capacity": "500 GB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XC95WLQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$160",
            "price": "$80",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08F2L6TLR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$160",
            "price": "$320",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0929B5DWQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$160",
            "price": "$48",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BVW4A6Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$161",
            "price": "$1,233",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C8ZQSJZQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$163",
            "price": "$326",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08KGXK4Z6?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$163",
            "price": "$84",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07NDMNQ47?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$163",
            "price": "$1,255",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CNLCTBMX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$164",
            "price": "$82",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0874Y1FZZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$164",
            "price": "$21",
            "capacity": "64 GB x2",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B087G3RFR6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$164",
            "price": "$1,260",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08PVZ3K97?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$164",
            "price": "$84",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZJWMZW3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$165",
            "price": "$165",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071XVJB1F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$165",
            "price": "$99",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B086Q2RGPG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$165",
            "price": "$19",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009TOLK8Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$166",
            "price": "$315",
            "capacity": "1.9 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C31MZ38Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$166",
            "price": "$199",
            "capacity": "1.2 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LXAKE6W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$166",
            "price": "$21",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07M5HDB2G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$167",
            "price": "$83",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074SGF6J4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$167",
            "price": "$100",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0038JVJCS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$167",
            "price": "$167",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RBCL7SN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$167",
            "price": "$21",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H9DVLBB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$168",
            "price": "$43",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N15KEER?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$168",
            "price": "$22",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07MHNM57P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$168",
            "price": "$42",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0764WCXCV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$169",
            "price": "$169",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LXPENRR?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$169",
            "price": "$1,300",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CZV784Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$17",
            "capacity": "100 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009Q0WGQG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$87",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07GBKQ9ST?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$170",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B8G4R6CH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$170",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CD2TVQ98?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$20",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001AATPGO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$8",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00L1FXXAW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$170",
            "price": "$11",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7C7NCZ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$171",
            "price": "$328",
            "capacity": "1.9 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B1W53S3X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$171",
            "price": "$86",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01JR3PMLC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$172",
            "price": "$22",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07N7D2WBF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$172",
            "price": "$22",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YQ5M4V4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$172",
            "price": "$22",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079X7K6VP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$172",
            "price": "$55",
            "capacity": "320 GB",
            "warranty": "5 years",
            "formFactor": "Internal 2.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00DSUTVYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$172",
            "price": "$43",
            "capacity": "250 GB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B088BS3BDD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$173",
            "price": "$346",
            "capacity": "2 TB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071RDV7Z1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$173",
            "price": "$693",
            "capacity": "4 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08VKZHYTD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$174",
            "price": "$45",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08JD2LXXG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$174",
            "price": "$20",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002374L4Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$174",
            "price": "$45",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07VQPDM56?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$175",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07ZPRK68X?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$25",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07HM7F42Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$11",
            "capacity": "32 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07K82GRDV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$11",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07KXRGM8X?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$56",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08MJ3ZQK6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$88",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GTXVG9P?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$175",
            "price": "$45",
            "capacity": "256 GB",
            "warranty": "15 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084CJJRBW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$176",
            "price": "$1,349",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09L31CTD5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$176",
            "price": "$45",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07LBJC2D9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$176",
            "price": "$11",
            "capacity": "32 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07K82GRDV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$177",
            "price": "$1,361",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09YRZ3113?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$178",
            "price": "$14",
            "capacity": "16 GB x5",
            "warranty": "2 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B083VMR3PL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$178",
            "price": "$11",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7BYSFG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$178",
            "price": "$13",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CZ5J424?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$178",
            "price": "$42",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000MNOXCQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$181",
            "price": "$29",
            "capacity": "16 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07C5P6K6V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$181",
            "price": "$12",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7BYSFG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$182",
            "price": "$1,400",
            "capacity": "7.7 TB",
            "warranty": "2 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JRHM12F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$183",
            "price": "$183",
            "capacity": "1 TB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007JGB0EI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$183",
            "price": "$55",
            "capacity": "300 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0081TYGZM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$183",
            "price": "$55",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B013GUPRFS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$184",
            "price": "$94",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B097ZKMB21?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$184",
            "price": "$46",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0057YIICQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$184",
            "price": "$1,411",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B7NT4KZX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$184",
            "price": "$46",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K0S7GJU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$185",
            "price": "$26",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M04AA3X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$185",
            "price": "$370",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0929B5DWQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$185",
            "price": "$93",
            "capacity": "100 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01E0CUU1C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$185",
            "price": "$22",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00A2CM2CA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$186",
            "price": "$26",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JN66PTW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$187",
            "price": "$44",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00QN8QIDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$187",
            "price": "$12",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07KXRGM8X?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$187",
            "price": "$15",
            "capacity": "16 GB x5",
            "warranty": "2 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083VMR3PL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$187",
            "price": "$30",
            "capacity": "32 GB x5",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07N73LB4T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$187",
            "price": "$48",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BLJ148PQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$189",
            "price": "$9",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005ENK5C6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$189",
            "price": "$189",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5T6ZHGB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$190",
            "price": "$47",
            "capacity": "250 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00MBN73F4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$190",
            "price": "$190",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09MY93SHY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$190",
            "price": "$380",
            "capacity": "2 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08YMR64RT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$190",
            "price": "$1,459",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0D2PGNFT1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$191",
            "price": "$45",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AXH8KOS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$192",
            "price": "$192",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00QJEFZ3S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$193",
            "price": "$193",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007VPGJIY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$194",
            "price": "$46",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00062WUYQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$194",
            "price": "$1,491",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5KKR595?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$195",
            "price": "$97",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00R0WQFTG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$195",
            "price": "$25",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07LBSBX5L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$195",
            "price": "$25",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BWJGL74V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$195",
            "price": "$50",
            "capacity": "256 GB",
            "warranty": "1 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07WWCLHGM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$195",
            "price": "$50",
            "capacity": "256 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BDT4SHC6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$195",
            "price": "$31",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BZJMDXWX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$196",
            "price": "$28",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0032L3ZLA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$196",
            "price": "$196",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07BYHGNB5?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$197",
            "price": "$197",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073H552FJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$198",
            "price": "$95",
            "capacity": "480 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09TV6CL1M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$198",
            "price": "$95",
            "capacity": "480 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C5PYLPN5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$199",
            "price": "$1,525",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0868RT1TC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$199",
            "price": "$199",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C9P7WGRK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$199",
            "price": "$199",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C9P7WGRK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$200",
            "price": "$50",
            "capacity": "250 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QVP977F?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$200",
            "price": "$5",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000FEE7BE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$200",
            "price": "$51",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08JD2LXXG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$202",
            "price": "$6",
            "capacity": "32 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07R8GVGN9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$202",
            "price": "$64",
            "capacity": "32 GB x10",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01DJKRQBI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$202",
            "price": "$3,100",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPTFGD2X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$202",
            "price": "$388",
            "capacity": "1.9 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7RNNCS9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$202",
            "price": "$26",
            "capacity": "128 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0895V8J2C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$203",
            "price": "$195",
            "capacity": "960 GB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CVRXPBML?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$204",
            "price": "$24",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000SHJ4FW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$207",
            "price": "$53",
            "capacity": "256 GB",
            "warranty": "2 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07VQPDM56?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$208",
            "price": "$29",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VSJRTLA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$208",
            "price": "$1,597",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085TRCFSG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$208",
            "price": "$13",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XDCZ9J3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$208",
            "price": "$1,600",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5587W2H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$210",
            "price": "$54",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B94S6H7P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$210",
            "price": "$21",
            "capacity": "100 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TBBT9ZQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$210",
            "price": "$210",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09BCZBJHJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$211",
            "price": "$211",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07822Z77M?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$212",
            "price": "$27",
            "capacity": "64 GB x2",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CTMMNN9R?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$212",
            "price": "$53",
            "capacity": "250 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07864V6CK?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$212",
            "price": "$10",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000811DCA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$213",
            "price": "$10",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00TMMDBLM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$213",
            "price": "$64",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003DN3AB2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$214",
            "price": "$107",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08SWFTP9N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$215",
            "price": "$14",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0C2JP88N4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$216",
            "price": "$14",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7C7NCZ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$217",
            "price": "$52",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B076Y374ZH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$217",
            "price": "$108",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0781Z7Y3S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$217",
            "price": "$54",
            "capacity": "50 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0055PVAK4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$218",
            "price": "$2,786",
            "capacity": "12.8 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BH1VSS5W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$218",
            "price": "$1,676",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08PVZ3K97?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$219",
            "price": "$219",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RL812DW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$219",
            "price": "$7",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002OWHZMI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$219",
            "price": "$7",
            "capacity": "32 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002G12OBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$219",
            "price": "$5",
            "capacity": "25 GB",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000FEHVRG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$220",
            "price": "$70",
            "capacity": "32 GB x10",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Z44BQRG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$220",
            "price": "$110",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00R0WQ3IO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$222",
            "price": "$7",
            "capacity": "32 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B010NE3QHQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$223",
            "price": "$114",
            "capacity": "512 GB",
            "warranty": "1 year",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DHDVB1F?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$223",
            "price": "$29",
            "capacity": "128 GB",
            "warranty": "1 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07TMNRM9M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$225",
            "price": "$225",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083GH4MQH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$225",
            "price": "$450",
            "capacity": "2 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B083GHGFV7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$225",
            "price": "$29",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08LNGQZ7Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$226",
            "price": "$58",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07RCN2BFM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$226",
            "price": "$58",
            "capacity": "8.5 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00MU2JEXK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$226",
            "price": "$11",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005ENK5C6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$227",
            "price": "$116",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B097ZKMB21?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$227",
            "price": "$68",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003AQJUS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$228",
            "price": "$57",
            "capacity": "50 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004KNO5R2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$229",
            "price": "$69",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00UZFO5O2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$229",
            "price": "$229",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CDYDG2R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$229",
            "price": "$229",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0963KF3WL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$229",
            "price": "$55",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B093QL56WR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$230",
            "price": "$27",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00A657KX4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$230",
            "price": "$230",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07ZPRJFV1?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$231",
            "price": "$185",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0051B78HQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$231",
            "price": "$3,699",
            "capacity": "16 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08PPL7QL7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$231",
            "price": "$222",
            "capacity": "960 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHCL7HTL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$232",
            "price": "$209",
            "capacity": "900 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009QO888W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$232",
            "price": "$30",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B075JSDCC6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$233",
            "price": "$70",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XQZ88HV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$234",
            "price": "$6",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0017OXIFC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$234",
            "price": "$30",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07XVSLVBF?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$234",
            "price": "$1,800",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B8NTH9DY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$234",
            "price": "$1,800",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPTG1JDG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$234",
            "price": "$8",
            "capacity": "32 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002G12OBO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$234",
            "price": "$59",
            "capacity": "50 GB x5",
            "warranty": "2 years",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00A300GZG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$235",
            "price": "$235",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B007VPGJIY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$240",
            "price": "$10",
            "capacity": "8 GB x5",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01CKT1DTA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$240",
            "price": "$72",
            "capacity": "300 GB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0035G0J98?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$241",
            "price": "$58",
            "capacity": "240 GB",
            "warranty": "3 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B093QL56WR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$241",
            "price": "$3,700",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B8NVZ7TF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$242",
            "price": "$8",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002OWHZMI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$242",
            "price": "$62",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B94S6H7P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$242",
            "price": "$62",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09MDR5WT7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$243",
            "price": "$121",
            "capacity": "25 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003WOKJGG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$244",
            "price": "$78",
            "capacity": "64 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079W424YB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$244",
            "price": "$39",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09CFXHD9R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$244",
            "price": "$20",
            "capacity": "8 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XLMFCH3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$247",
            "price": "$8",
            "capacity": "32 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01FZPCT6M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$247",
            "price": "$6",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0017OXIFC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$247",
            "price": "$32",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01N1UXH7S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$247",
            "price": "$17",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00PV1K1N4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$249",
            "price": "$16",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C2JP88N4?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$250",
            "price": "$8",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DD9B3NH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$250",
            "price": "$40",
            "capacity": "32 GB x5",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZJMDXWX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$250",
            "price": "$250",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0851L494L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$250",
            "price": "$16",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073K38V1P?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$253",
            "price": "$76",
            "capacity": "300 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01MG2XPVM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$253",
            "price": "$190",
            "capacity": "25 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XK1X9XQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$253",
            "price": "$130",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B3PFSV34?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$254",
            "price": "$32",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LNGQZ7Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$254",
            "price": "$65",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RCN2BFM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$254",
            "price": "$30",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AEF7RLG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$255",
            "price": "$60",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002ZZ2CAO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$259",
            "price": "$6",
            "capacity": "25 GB",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000FEHVRG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$260",
            "price": "$4,000",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084P4WX11?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$263",
            "price": "$8",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B005LFT3QG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$264",
            "price": "$135",
            "capacity": "512 GB",
            "warranty": "10 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CP2XLFQ7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$265",
            "price": "$8",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07J1G261N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$265",
            "price": "$34",
            "capacity": "32 GB x4",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07Z58QZ3B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$265",
            "price": "$66",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001QYL530?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$266",
            "price": "$9",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005LFT3QG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$267",
            "price": "$100",
            "capacity": "25 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XIOYK2O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$267",
            "price": "$200",
            "capacity": "25 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XK1Z6UK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$268",
            "price": "$68",
            "capacity": "128 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CCSZXSKQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$269",
            "price": "$17",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08T6QSBPJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$271",
            "price": "$139",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08FT45Q9V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$271",
            "price": "$19",
            "capacity": "700 MB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00K58G362?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$274",
            "price": "$70",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BXH1V287?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$276",
            "price": "$13",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074Z2GDDY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$280",
            "price": "$105",
            "capacity": "25 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XIOZMQM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$281",
            "price": "$9",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01LORO7Z6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$282",
            "price": "$9",
            "capacity": "32 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073JWXGNT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$282",
            "price": "$45",
            "capacity": "16 GB x10",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SS5DL6B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$282",
            "price": "$9",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09ZS1PHV4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$284",
            "price": "$20",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01M7541ZE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$287",
            "price": "$147",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B085DHR8SY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$288",
            "price": "$23",
            "capacity": "16 GB x5",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NIEIRVY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$288",
            "price": "$37",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B2P9FM18?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$289",
            "price": "$37",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01N1UXH7S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$292",
            "price": "$34",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0009O7JL0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$293",
            "price": "$150",
            "capacity": "512 GB",
            "warranty": "1 year",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZZNQLMZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$294",
            "price": "$14",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00UZNX7AM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$295",
            "price": "$1,134",
            "capacity": "3.8 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B8NRNBXP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$296",
            "price": "$19",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08T6QSBPJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$296",
            "price": "$21",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009A55UA6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$297",
            "price": "$9",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09X7G85JC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$297",
            "price": "$89",
            "capacity": "300 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0025VSUFE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$297",
            "price": "$38",
            "capacity": "128 GB",
            "warranty": "1 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TMNRM9M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$297",
            "price": "$7",
            "capacity": "4.7 GB x5",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00SO5YYOG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$298",
            "price": "$14",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074WV4VHQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$299",
            "price": "$10",
            "capacity": "32 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07DG5KNKG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$300",
            "price": "$1,198",
            "capacity": "4 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08TCHGHMM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$300",
            "price": "$10",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LORO7Z6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$301",
            "price": "$154",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01MR4VOBZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$302",
            "price": "$10",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09ZS1PHV4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$305",
            "price": "$39",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07ZJCQQB7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$306",
            "price": "$14",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0116WL8GU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$306",
            "price": "$14",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007CCXOJS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$306",
            "price": "$153",
            "capacity": "50 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003M6AGWQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$308",
            "price": "$45",
            "capacity": "146 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000JIP1Y8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$309",
            "price": "$309",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07JWD1ZYF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$312",
            "price": "$39",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001FOPW1W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$312",
            "price": "$10",
            "capacity": "32 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01FZPCT6M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$312",
            "price": "$40",
            "capacity": "128 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CL82X7HQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$312",
            "price": "$160",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085DHR8SY?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$312",
            "price": "$125",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00858CYJY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$313",
            "price": "$13",
            "capacity": "8.5 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000UX0F0C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$314",
            "price": "$314",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073H552FJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$316",
            "price": "$15",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00WT46QX0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$320",
            "price": "$40",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00P870NES?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$320",
            "price": "$23",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000OVW0VC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$320",
            "price": "$23",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00Q2XVQ2A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$322",
            "price": "$4,942",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09VNH4Q24?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$327",
            "price": "$10",
            "capacity": "32 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07DG5KNKG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$328",
            "price": "$23",
            "capacity": "700 MB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B9626HLL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$328",
            "price": "$23",
            "capacity": "700 MB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BHY16CQR?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$329",
            "price": "$23",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B016MHFGQC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$330",
            "price": "$165",
            "capacity": "500 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B078218TWQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$331",
            "price": "$635",
            "capacity": "1.9 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B8NN24VN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$333",
            "price": "$200",
            "capacity": "600 GB",
            "warranty": "5 years",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003FW9T0M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$335",
            "price": "$134",
            "capacity": "400 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09QMSS528?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$337",
            "price": "$8",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0032JUH22?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$342",
            "price": "$11",
            "capacity": "16 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00FQMWTGK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$343",
            "price": "$175",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08927HG8B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$345",
            "price": "$41",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008FWTZBK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$348",
            "price": "$16",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B007W1Y5HY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$348",
            "price": "$16",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004E3EJ6K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$349",
            "price": "$16",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008BNNK00?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$349",
            "price": "$16",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00TMMDBLM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$350",
            "price": "$28",
            "capacity": "8 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07PQF9X75?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$350",
            "price": "$350",
            "capacity": "1 TB",
            "warranty": "2 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZWLCM4R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$350",
            "price": "$90",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B3PN632Y?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$351",
            "price": "$44",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001L8J4UW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$351",
            "price": "$45",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B2P9FM18?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$352",
            "price": "$90",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TDNQX6L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$352",
            "price": "$90",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LNR8C1H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$352",
            "price": "$90",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BXH1V287?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$361",
            "price": "$6",
            "capacity": "16 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0143RTB1E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$361",
            "price": "$90",
            "capacity": "50 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BTPRVPLQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$363",
            "price": "$46",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00NUB2TWI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$363",
            "price": "$26",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004E2RYNQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$365",
            "price": "$2,800",
            "capacity": "7.7 TB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085WBH1BZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$365",
            "price": "$12",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09X7G85JC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$110",
            "capacity": "300 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0081TYGZM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$165",
            "capacity": "450 GB",
            "warranty": "3 months",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07HGJPPLJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$17",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00Q4KPLOU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$47",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7DRSV8Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$12",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01J5RHBQ4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$100",
            "capacity": "272 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07RM5V4TF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$367",
            "price": "$353",
            "capacity": "960 GB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07KCPT9WJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$368",
            "price": "$12",
            "capacity": "32 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06XYHN68L?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$369",
            "price": "$2,835",
            "capacity": "7.7 TB",
            "warranty": "1 month",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CJX18QF5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$371",
            "price": "$222",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B016YKFAKE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$372",
            "price": "$6",
            "capacity": "16 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B074B4P7KD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$375",
            "price": "$60",
            "capacity": "16 GB x10",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08MHTV9HN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$375",
            "price": "$150",
            "capacity": "400 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09QMSS528?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$375",
            "price": "$192",
            "capacity": "512 GB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07C8Y31G2?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$376",
            "price": "$361",
            "capacity": "960 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JD9GZN7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$380",
            "price": "$380",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JWD1ZYF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$383",
            "price": "$18",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00007IFK3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$383",
            "price": "$18",
            "capacity": "4.7 GB x10",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01L0R38WS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$383",
            "price": "$18",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01L0R6BD6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$383",
            "price": "$18",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000H34IN0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$383",
            "price": "$383",
            "capacity": "1 TB",
            "warranty": "5 years",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BYHGNB5?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$387",
            "price": "$18",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B010DJOACQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$388",
            "price": "$745",
            "capacity": "1.9 TB",
            "warranty": "5 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07CYCL9GY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$389",
            "price": "$99",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09MDR5WT7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$391",
            "price": "$200",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CN1NPM5H?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$391",
            "price": "$200",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07M9644N1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$392",
            "price": "$49",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003M6AGYY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$392",
            "price": "$49",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003I85O8E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$396",
            "price": "$99",
            "capacity": "50 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003M6AGWG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$400",
            "price": "$20",
            "capacity": "50 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002UD7BWU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$400",
            "price": "$20",
            "capacity": "50 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000H4FO9G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$402",
            "price": "$51",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07YNSS9X2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$404",
            "price": "$6",
            "capacity": "4.7 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0000CFY86?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$406",
            "price": "$13",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DLJWDRK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$412",
            "price": "$3,168",
            "capacity": "7.7 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B63NV3YH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$416",
            "price": "$208",
            "capacity": "500 GB",
            "warranty": "2 years",
            "formFactor": "External 3.5\"",
            "technology": "HDD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071DGR6W5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$422",
            "price": "$27",
            "capacity": "64 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B01D8H087G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$425",
            "price": "$255",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0818W7XZ9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$426",
            "price": "$218",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RSFD7TH?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$430",
            "price": "$220",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07S842GWW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$430",
            "price": "$61",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002ZZ7S62?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$433",
            "price": "$30",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00POO7Y2Y?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$433",
            "price": "$433",
            "capacity": "1 TB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079L64XYM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$434",
            "price": "$217",
            "capacity": "500 GB",
            "warranty": "3 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073GZBT36?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$435",
            "price": "$223",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08927HG8B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$444",
            "price": "$7",
            "capacity": "16 GB",
            "warranty": "10 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0143RTB1E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$455",
            "price": "$116",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B085DBCJTG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$457",
            "price": "$114",
            "capacity": "25 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001FOPW3K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$458",
            "price": "$37",
            "capacity": "8 GB x10",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07D7WFVK5?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$466",
            "price": "$2",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B004SZT9Q4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$468",
            "price": "$15",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0749KG1JK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$469",
            "price": "$60",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07YNSS9X2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$469",
            "price": "$300",
            "capacity": "640 GB",
            "warranty": "No warranty",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJT8FMQJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$473",
            "price": "$142",
            "capacity": "300 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01MG2XPVM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$475",
            "price": "$8",
            "capacity": "16 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B074B4P7KD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$480",
            "price": "$11",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0000CFYMD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$487",
            "price": "$390",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0075LX48C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$488",
            "price": "$125",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00IAYFD0A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$495",
            "price": "$495",
            "capacity": "1 TB",
            "warranty": "No warranty",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07P9WY25C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$499",
            "price": "$35",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01AMPF3Z4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$500",
            "price": "$32",
            "capacity": "64 GB",
            "warranty": "1 year",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00NUB2RPW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$503",
            "price": "$64",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B085CNSZMC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$507",
            "price": "$74",
            "capacity": "146 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0012PUT7Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$508",
            "price": "$63",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000X1QH1C?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$514",
            "price": "$12",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000WTO352?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$515",
            "price": "$33",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CRL39C8D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$523",
            "price": "$67",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00EZE813K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$530",
            "price": "$2",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00008KX60?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$531",
            "price": "$12",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00006LHK0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$538",
            "price": "$9",
            "capacity": "16 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B073K14CVB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$544",
            "price": "$13",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00026CL54?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$547",
            "price": "$35",
            "capacity": "64 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00U77V5IW?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$548",
            "price": "$80",
            "capacity": "146 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000JIP1Y8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$554",
            "price": "$8",
            "capacity": "4.7 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00013UXRQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$562",
            "price": "$66",
            "capacity": "118 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09MSB59SK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$565",
            "price": "$24",
            "capacity": "8.5 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0044A4RCO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$571",
            "price": "$40",
            "capacity": "700 MB x100",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08M4CSY2X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$571",
            "price": "$160",
            "capacity": "280 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0772XB3MY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$580",
            "price": "$74",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NUB2TWI?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$586",
            "price": "$75",
            "capacity": "128 GB",
            "warranty": "3 years",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079WT87M9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$588",
            "price": "$353",
            "capacity": "600 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SAS",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0818W7XZ9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$594",
            "price": "$38",
            "capacity": "64 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01D8H087G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$598",
            "price": "$15",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003WO7XP6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$602",
            "price": "$71",
            "capacity": "118 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09MSB59SK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$602",
            "price": "$3",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00015EDS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$617",
            "price": "$29",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00008L3IS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$618",
            "price": "$10",
            "capacity": "16 GB",
            "warranty": "10 years",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B073K14CVB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$620",
            "price": "$50",
            "capacity": "4 GB x20",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07J23YQLC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$621",
            "price": "$159",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085DBCJTG?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$621",
            "price": "$78",
            "capacity": "25 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001BIVQRQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$624",
            "price": "$5",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08CRMBD93?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$624",
            "price": "$20",
            "capacity": "32 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09VLJS2G8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$625",
            "price": "$80",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XC3D96V?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$628",
            "price": "$3",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000RLBWJK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$629",
            "price": "$81",
            "capacity": "128 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0895WGTVN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$638",
            "price": "$90",
            "capacity": "4.7 GB x30",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XK1R8EC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$666",
            "price": "$16",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BT2UDVO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$675",
            "price": "$173",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09XC5FX1Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$675",
            "price": "$27",
            "capacity": "4 GB x10",
            "warranty": "1 year",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JB3NXIS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$703",
            "price": "$90",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C1TPNNTK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$703",
            "price": "$90",
            "capacity": "128 GB",
            "warranty": "2 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0895WGTVN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$703",
            "price": "$90",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09WLWWL9B?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$703",
            "price": "$45",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00H3HEUYK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$709",
            "price": "$181",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XC5FX1Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$712",
            "price": "$57",
            "capacity": "8 GB x10",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08MQVKXRN?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$714",
            "price": "$10,960",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07TBLWKFT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$723",
            "price": "$17",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0006B05YY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$732",
            "price": "$172",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0011B91HA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$735",
            "price": "$88",
            "capacity": "120 GB",
            "warranty": "2 years",
            "formFactor": "External",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07QSBXTPQ?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$737",
            "price": "$52",
            "capacity": "4.7 GB x15",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XINJDDQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$740",
            "price": "$174",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00XK1SXSW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$742",
            "price": "$95",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085CNSZMC?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$742",
            "price": "$190",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B071YTK82K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$743",
            "price": "$21",
            "capacity": "4.7 GB x6",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00CKGS1DK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$745",
            "price": "$18",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0006B05YY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$747",
            "price": "$48",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09XC5GG83?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$749",
            "price": "$6",
            "capacity": "8 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B002U28LZC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$750",
            "price": "$48",
            "capacity": "64 GB",
            "warranty": "1 year",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NUB2RPW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$750",
            "price": "$6",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00M1NGRME?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$750",
            "price": "$6",
            "capacity": "8 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002U28LZC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$764",
            "price": "$4",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0002J5HH0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$765",
            "price": "$199",
            "capacity": "260 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C4H7SLJ1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$766",
            "price": "$196",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LMMT9KP?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$766",
            "price": "$90",
            "capacity": "4.7 GB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B005C7UCUY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$772",
            "price": "$25",
            "capacity": "32 GB",
            "warranty": "5 years",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00KSLLR12?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$779",
            "price": "$623",
            "capacity": "800 GB",
            "warranty": "No warranty",
            "formFactor": "Tape",
            "technology": "LTO-4",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J4SAXBK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$779",
            "price": "$748",
            "capacity": "960 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0C6V6FWFS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$781",
            "price": "$200",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IAYFD0A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$790",
            "price": "$13",
            "capacity": "16 GB",
            "warranty": "1 month",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09PYZSH1Z?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$797",
            "price": "$12,237",
            "capacity": "15.4 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPN3MC1T?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$805",
            "price": "$12,363",
            "capacity": "15.4 TB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPTF42NB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$810",
            "price": "$104",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08YF7PB14?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$815",
            "price": "$52",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B085CNXD8Y?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$817",
            "price": "$192",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0011B5DEK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$834",
            "price": "$53",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09XC5GG83?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$840",
            "price": "$430",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BZ7RW5TC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$844",
            "price": "$108",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08GGFCZPW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$847",
            "price": "$4",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004SZT9Q4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$847",
            "price": "$199",
            "capacity": "4.7 GB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00JPTC6UE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$847",
            "price": "$1,626",
            "capacity": "1.9 TB",
            "warranty": "No warranty",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CPTGKWPV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$850",
            "price": "$217",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RS9H4F7?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$851",
            "price": "$40",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0002LHSG6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$851",
            "price": "$4",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00005U8NM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$855",
            "price": "$239",
            "capacity": "280 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B077R2VBJZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$859",
            "price": "$55",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00IAYFDIC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$862",
            "price": "$7",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07BZ5SY18?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$891",
            "price": "$114",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08GGFCZPW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$893",
            "price": "$21",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00008L3IU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$898",
            "price": "$230",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07T9JWPBJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$898",
            "price": "$115",
            "capacity": "128 GB",
            "warranty": "1 year",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00U77V5KU?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$898",
            "price": "$115",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08YF7PB14?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$908",
            "price": "$29",
            "capacity": "32 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B5B2WL5R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$912",
            "price": "$29",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CKPR26JM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$937",
            "price": "$60",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085CNXD8Y?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$957",
            "price": "$45",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00006HYOM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$967",
            "price": "$124",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00IAYFDJG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$970",
            "price": "$31",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00ECEVEP0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$975",
            "price": "$31",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00NUB2Q9E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$977",
            "price": "$250",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071YTK82K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$979",
            "price": "$46",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0000DEYZW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$988",
            "price": "$23",
            "capacity": "4.7 GB x5",
            "warranty": "2 years",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0000DEYZU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,004",
            "price": "$24",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0011HY7EQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,005",
            "price": "$35",
            "capacity": "700 MB x50",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000LUC8WW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,015",
            "price": "$65",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IAYFDIC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,019",
            "price": "$163",
            "capacity": "160 GB",
            "warranty": "10 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CLTX4327?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,027",
            "price": "$18",
            "capacity": "700 MB x25",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000CCAQ0K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,030",
            "price": "$170",
            "capacity": "165 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BPBPDCKX?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,031",
            "price": "$66",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B3PM9W6V?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,031",
            "price": "$33",
            "capacity": "32 GB",
            "warranty": "5 years",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5B2WL5R?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,032",
            "price": "$17",
            "capacity": "16 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09P78GGKZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,039",
            "price": "$42",
            "capacity": "2 GB x20",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07SCR8G3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,046",
            "price": "$8",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000WJ725U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,053",
            "price": "$539",
            "capacity": "512 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B085LQSN1X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,060",
            "price": "$699",
            "capacity": "660 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B6GTC6XS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,062",
            "price": "$5",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00015EDS4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,062",
            "price": "$68",
            "capacity": "64 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B081B4X9TG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,062",
            "price": "$8",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000WJ725U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,083",
            "price": "$260",
            "capacity": "240 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CRGV29V2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,091",
            "price": "$180",
            "capacity": "165 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B5G573ZV?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,098",
            "price": "$27",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000HDDKRU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,106",
            "price": "$5",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000067S5N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,124",
            "price": "$18",
            "capacity": "16 GB",
            "warranty": "1 year",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08LD5NFN6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,150",
            "price": "$46",
            "capacity": "2 GB x20",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07SCR8G3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,150",
            "price": "$92",
            "capacity": "80 GB",
            "warranty": "No warranty",
            "formFactor": "Internal 3.5\"",
            "technology": "HDD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00450GB0O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,162",
            "price": "$767",
            "capacity": "660 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B6GTC6XS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,172",
            "price": "$300",
            "capacity": "256 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7KCWX5N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,186",
            "price": "$19",
            "capacity": "16 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09P78GGKZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,191",
            "price": "$28",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00BCUP0NE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,196",
            "price": "$153",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RSGN85K?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,198",
            "price": "$30",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003WN8U40?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,220",
            "price": "$29",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07P1YJJ94?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,222",
            "price": "$39",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NUB2Q9E?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,234",
            "price": "$29",
            "capacity": "4.7 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00008L3IU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,250",
            "price": "$40",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00ECEVEP0?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,273",
            "price": "$127",
            "capacity": "100 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004VC9ZMW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,274",
            "price": "$6",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000067S9U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,278",
            "price": "$32",
            "capacity": "25 GB",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DJC5PVM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,288",
            "price": "$152",
            "capacity": "118 GB",
            "warranty": "1 year",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B078T2SM3D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,292",
            "price": "$65",
            "capacity": "50 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01AL5KA9O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,297",
            "price": "$332",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BMBBKWLT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,300",
            "price": "$520",
            "capacity": "400 GB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07J2BSK7S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,328",
            "price": "$340",
            "capacity": "256 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BMBBKWLT?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,334",
            "price": "$374",
            "capacity": "280 GB",
            "warranty": "1 year",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07776HSPW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,353",
            "price": "$6",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002K8S5HA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,394",
            "price": "$223",
            "capacity": "160 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08DRQ6KLL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,406",
            "price": "$450",
            "capacity": "320 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0BGQF6L7F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,450",
            "price": "$580",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08R3YKYLQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,473",
            "price": "$189",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B7CCVY4M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,484",
            "price": "$190",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00IAYFDJG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,485",
            "price": "$7",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000067S9U?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,489",
            "price": "$70",
            "capacity": "4.7 GB x10",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0002LHSG6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,521",
            "price": "$578",
            "capacity": "380 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07K6714WQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,538",
            "price": "$38",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HYOMPIA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,562",
            "price": "$200",
            "capacity": "128 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00HUWW8KQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,562",
            "price": "$100",
            "capacity": "64 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B081B4X9TG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,573",
            "price": "$590",
            "capacity": "375 GB",
            "warranty": "3 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0D1N9TQG7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,625",
            "price": "$130",
            "capacity": "80 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08DRNDVHY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,625",
            "price": "$208",
            "capacity": "128 GB",
            "warranty": "5 years",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07H9GDFRW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,638",
            "price": "$1,048",
            "capacity": "320 GB x2",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BNJT4GGD?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,650",
            "price": "$528",
            "capacity": "160 GB x2",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09SPCZX3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,686",
            "price": "$43",
            "capacity": "8.5 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000H34IRG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,702",
            "price": "$8",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00006689D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,720",
            "price": "$2,751",
            "capacity": "1.6 TB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08R3YZVDF?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,745",
            "price": "$558",
            "capacity": "320 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BGQF6L7F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,750",
            "price": "$14",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00M1NGRME?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,772",
            "price": "$25",
            "capacity": "4.7 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09392TLP1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,812",
            "price": "$14",
            "capacity": "8 GB",
            "warranty": "5 years",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000W05O5O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,827",
            "price": "$731",
            "capacity": "400 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08R3YKYLQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,845",
            "price": "$118",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09RSKRT65?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,864",
            "price": "$1,491",
            "capacity": "800 GB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08R3ZXBLH?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,864",
            "price": "$699",
            "capacity": "375 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B071VRNXVM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,874",
            "price": "$15",
            "capacity": "8 GB",
            "warranty": "5 years",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000W05O5O?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,874",
            "price": "$30",
            "capacity": "16 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B009S61AC4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$1,928",
            "price": "$62",
            "capacity": "32 GB",
            "warranty": "No warranty",
            "formFactor": "microSD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B3PJV232?tag=synack-20&linkCode=ogv&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,000",
            "price": "$640",
            "capacity": "160 GB x2",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09SPCZX3M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,015",
            "price": "$129",
            "capacity": "64 GB",
            "warranty": "No warranty",
            "formFactor": "USB",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01GHZM24S?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,060",
            "price": "$51",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01IWZBF26?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,067",
            "price": "$992",
            "capacity": "480 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B077767L4X?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,126",
            "price": "$10",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00007BKPJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,143",
            "price": "$343",
            "capacity": "160 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DRQ6KLL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,159",
            "price": "$22",
            "capacity": "2 GB x5",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B58Z6YKL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,269",
            "price": "$113",
            "capacity": "25 GB x2",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BJ6G6Q8M?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,308",
            "price": "$2",
            "capacity": "650 MB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "CD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0036R6IVO?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,376",
            "price": "$190",
            "capacity": "80 GB",
            "warranty": "5 years",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08DRNDVHY?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,394",
            "price": "$191",
            "capacity": "80 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B08F2RCYGS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,420",
            "price": "$242",
            "capacity": "100 GB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07KPRGLC1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,500",
            "price": "$70",
            "capacity": "1.4 GB x20",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000JV3CJG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,934",
            "price": "$14",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0007ZEM7A?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$2,998",
            "price": "$12",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B09P76VPZJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,096",
            "price": "$31",
            "capacity": "2 GB x5",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B58Z6YKL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,098",
            "price": "$12",
            "capacity": "2 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0B2JYXYVG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,187",
            "price": "$15",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00009MJJZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,189",
            "price": "$15",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004CJ9KZQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,249",
            "price": "$26",
            "capacity": "4 GB x2",
            "warranty": "1 year",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07S2PQ1V7?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,325",
            "price": "$4,988",
            "capacity": "1.5 TB",
            "warranty": "5 years",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07JCYJMY9?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,333",
            "price": "$1,250",
            "capacity": "375 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B071VRNXVM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,415",
            "price": "$16",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00J88KBJK?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,447",
            "price": "$29",
            "capacity": "8.5 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B008YQAFA2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,498",
            "price": "$14",
            "capacity": "2 GB x2",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0B2JYXYVG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,536",
            "price": "$5",
            "capacity": "1.4 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0009XAKMG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,550",
            "price": "$7",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000FGNM6I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,554",
            "price": "$1,333",
            "capacity": "375 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084RJ5QC3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,802",
            "price": "$36",
            "capacity": "4.7 GB x2",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00NKQ7MO8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,833",
            "price": "$33",
            "capacity": "8.5 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0080Q22KC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,840",
            "price": "$96",
            "capacity": "25 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B002RT85RW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,937",
            "price": "$315",
            "capacity": "80 GB",
            "warranty": "No warranty",
            "formFactor": "CFexpress",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B08F2RCYGS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$3,993",
            "price": "$28",
            "capacity": "1.4 GB x5",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000799LR2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,159",
            "price": "$416",
            "capacity": "100 GB",
            "warranty": "5 years",
            "formFactor": "U.2/U.3",
            "technology": "NVMe",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B07KPRGLC1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,164",
            "price": "$31",
            "capacity": "7.5 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "BD-RE",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000UUJWSQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,168",
            "price": "$20",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00VGRBVU4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,245",
            "price": "$17",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09P76VPZJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,248",
            "price": "$17",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CRL4ZVHZ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,440",
            "price": "$444",
            "capacity": "100 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "SATA",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B084RF3472?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,455",
            "price": "$9",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000FGNM6I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,534",
            "price": "$21",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B004CJ9KTW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,577",
            "price": "$22",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003ZDNZSS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,823",
            "price": "$23",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B003ZDNZSS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,986",
            "price": "$40",
            "capacity": "8 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000XH2CS8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$4,999",
            "price": "$35",
            "capacity": "1.4 GB x5",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00179AZ2Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5,354",
            "price": "$30",
            "capacity": "2.8 GB x2",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000O8UJ6I?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5,357",
            "price": "$15",
            "capacity": "2.8 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B001RCHUFS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5,432",
            "price": "$22",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000ZRVURU?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5,485",
            "price": "$22",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0007QU6ZQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5,528",
            "price": "$26",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00009YFS3?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$5,952",
            "price": "$25",
            "capacity": "1.4 GB x3",
            "warranty": "1 year",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000799LQ8?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,290",
            "price": "$13",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09HK8R16N?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,325",
            "price": "$1,771",
            "capacity": "280 GB",
            "warranty": "No warranty",
            "formFactor": "Internal",
            "technology": "SSD",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B07FNN76DJ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,357",
            "price": "$30",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B004CJ9KTW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,417",
            "price": "$27",
            "capacity": "1.4 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00081NX5K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,495",
            "price": "$13",
            "capacity": "1 GB x2",
            "warranty": "1 year",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CVVQLDW2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,745",
            "price": "$13",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "SD",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079LZMPX1?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,748",
            "price": "$27",
            "capacity": "2 GB x2",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0BCZNS327?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$6,917",
            "price": "$33",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B079B76WG6?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,143",
            "price": "$30",
            "capacity": "1.4 GB x3",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0016OZ7K2?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,495",
            "price": "$15",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B089ST2HRB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,657",
            "price": "$36",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00WV6MKZE?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,830",
            "price": "$783",
            "capacity": "100 GB",
            "warranty": "No warranty",
            "formFactor": "M.2",
            "technology": "NVMe",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CFF738CW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,857",
            "price": "$11",
            "capacity": "1.4 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0009XAN9Q?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,906",
            "price": "$37",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B01LYUGF3F?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,975",
            "price": "$16",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B09P762PFS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$7,995",
            "price": "$16",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CRL3D78D?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8,698",
            "price": "$35",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B003QJPPWA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8,721",
            "price": "$41",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000E0LLCM?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8,988",
            "price": "$36",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0007QU6ZQ?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$8,995",
            "price": "$18",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00EKKM6MG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$9,255",
            "price": "$44",
            "capacity": "4.7 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-R",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B06W2PG71W?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$11,990",
            "price": "$12",
            "capacity": "1 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0735FCLXC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$12,812",
            "price": "$51",
            "capacity": "2 GB x2",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0CXRBJPGL?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13,425",
            "price": "$27",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B00AEUWDTC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$13,437",
            "price": "$215",
            "capacity": "16 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0845PKCTB?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14,143",
            "price": "$20",
            "capacity": "1.4 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0032JYV56?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$14,750",
            "price": "$21",
            "capacity": "1.4 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0032JYV56?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$16,154",
            "price": "$45",
            "capacity": "2.8 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0002JUIM4?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$17,445",
            "price": "$35",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00AEUWDTC?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$19,945",
            "price": "$40",
            "capacity": "2 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B00EKKM6MG?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$20,950",
            "price": "$21",
            "capacity": "1 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B0046ZZ95G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$21,414",
            "price": "$30",
            "capacity": "1.4 GB",
            "warranty": "No warranty",
            "formFactor": "Optical",
            "technology": "DVD-RW",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0017TMPLA?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$22,990",
            "price": "$23",
            "capacity": "1 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "Used",
            "link": "https://www.amazon.com/dp/B000H3E4PW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$28,890",
            "price": "$29",
            "capacity": "1 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000ZNWOSS?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35,790",
            "price": "$36",
            "capacity": "1 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0046ZZ95G?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$35,950",
            "price": "$36",
            "capacity": "1 GB",
            "warranty": "No warranty",
            "formFactor": "Compact",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B000H3E4PW?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        },
        {
            "pricePerTB": "$74,998",
            "price": "$300",
            "capacity": "4 GB",
            "warranty": "No warranty",
            "formFactor": "CFast",
            "technology": "Flash",
            "condition": "New",
            "link": "https://www.amazon.com/dp/B0931XBN3K?tag=liveprice02-20&linkCode=111&th=1&psc=1"
        }
    ]
    
    );

return (
    <DataContext.Provider value={{ data, setData }}>
        {children}
    </DataContext.Provider>
);
};

// Hook to use the data context
export const useData = () => useContext(DataContext);
