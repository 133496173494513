import React from 'react';

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div style={modalStyle}>
            <div style={modalContentStyle}>
                <h1>Privacy Policy for Disk Price List</h1>
                <h2>1. Introduction</h2>
                <p>Welcome to the Disk Price List. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at contact@diskpricelist.com.</p>
                
                <h2>2. Information We Collect</h2>
                <p>As visitors browse our website, we collect information related to your interaction with and usage of our website. The information we collect includes:</p>
                <ul>
                    <li><strong>Usage Data:</strong> This includes information on how you use our website, such as page views, site navigation paths, and query parameters.</li>
                    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to maintain the functionality of our website and enhance user experience. You can refuse to use cookies by selecting the appropriate settings on your browser.</li>
                </ul>
                
                <h2>3. How We Use Your Information</h2>
                <p>We use the information we collect in various ways, including to:</p>
                <ul>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                </ul>

                <button onClick={onClose} style={closeButtonStyle}>Close</button>
            </div>
        </div>
    );
};

const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
};

const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    width: '80%',
    maxWidth: '600px'
};

const closeButtonStyle = {
    marginTop: '10px',
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#007BFF',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
};

export default PrivacyPolicyModal;
