import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useData } from '../data/DataContext'; // Adjust the path according to your structure
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import LinkRenderer from './LinkRenderer';
import CapacityFilter from './CapacityFilter'

const MyAgGrid = () => {
    const { data } = useData();

    const pricePerTBValueGetter = params => {
        const pricePerTB = params.data.pricePerTB.replace(/[^0-9.-]+/g, "");
        return parseFloat(pricePerTB);
    };
    

    const capacityValueGetter = params => {
        const capacity = params.data.capacity;
        const number = parseFloat(capacity);
        const isTB = capacity.toLowerCase().includes('TB');
        return isTB ? number * 1024 : number; // Converts TB to GB if necessary
    };

    const cleanPrice = (priceString) => {
        return parseFloat(priceString.replace(/[^0-9.-]+/g, ""));
    }

    // const columnDefs = [
    //     //{ headerName: "ID", field: "id", filter: 'agNumberColumnFilter' },
    //     { headerName: "Price per TB", field: "pricePerTB", filter: 'agTextColumnFilter' },
    //     { headerName: "Price", field: "price", filter: 'agNumberColumnFilter' },
    //     { headerName: "Capacity (GB)", field: "capacity", filter: 'agNumberColumnFilter', valueGetter: capacityValueGetter },
    //     { headerName: "Warranty", field: "warranty", filter: 'agTextColumnFilter' },
    //     { headerName: "Form Factor", field: "formFactor", filter: 'agTextColumnFilter' },
    //     { headerName: "Technology", field: "technology", filter: 'agTextColumnFilter' },
    //     { headerName: "Condition", field: "condition", filter: 'agTextColumnFilter' },
    //     {
    //         headerName: "Link",
    //         field: "link",
    //         cellRenderer: LinkRenderer,
    //         filter: false
    //     }
    // ];

    const columnDefs = [
        {
            headerName: "Price per TB",
            field: "pricePerTB",
            filter: false,
            //valueGetter: pricePerTBValueGetter
        },
        {
            headerName: "Price",
            field: "price",
            filter: false,
            //filter: 'agNumberColumnFilter',
            //valueGetter: params => cleanPrice(params.data.price)
        },
        {
            headerName: "Capacity (GB)",
            field: "capacity",
            filter: 'agNumberColumnFilter',
            valueGetter: capacityValueGetter
        },
        // {
        //     headerName: "Capacity",
        //     field: "capacity",
        //     filter: CapacityFilter,
        //     valueGetter: params => {
        //         const capacity = params.data.capacity;
        //         return capacity.includes('TB') ? parseFloat(capacity) * 1024 : parseFloat(capacity);
        //     }
        // },        
        {
            headerName: "Warranty",
            field: "warranty",
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Form Factor",
            field: "formFactor",
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Technology",
            field: "technology",
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Condition",
            field: "condition",
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Link",
            field: "link",
            cellRenderer: LinkRenderer,
            filter: false
        }
    ];
    

    return (
        <div className="ag-theme-alpine" style={{ height: 2400, width: '100%' }}>
            <AgGridReact
                columnDefs={columnDefs}
                rowData={data}
                defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                    filter: true,
                    floatingFilter: true,
                }}
                frameworkComponents={{ linkRenderer: LinkRenderer }} 
            />
        </div>
    );
};

export default MyAgGrid;