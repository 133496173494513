// // LinkRenderer.js
// // LinkRenderer.js
// import React from 'react';
// const LinkRenderer = ({ value }) => {
//     console.log("Link Renderer loaded with value:", value);
//     return <a href={value} target="_blank" rel="noopener noreferrer">View Product</a>;
// };

// export default LinkRenderer;

// LinkRenderer.js
import React from "react";

export default (params) => {
  return (
    <a href={`${params.value}`} target="_blank">
      {params.value}
    </a>
  )
}
